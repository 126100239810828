import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  CSVBoxSheetsEnum,
  HookState,
  PermissionNamesEnums,
  PopupTypeEnum,
  TableKeyEnum,
  ToolTipLineVariantEnum,
} from '@interfaces';
import {
  BudgetSummary,
  ConfirmationModal,
  CSVUploader,
  Filter,
  IsProductionBuildCheckbox,
  LoadingSkeleton,
  MilestoneList,
  MilestoneListV2,
  ServiceMessage,
  StyledBox,
  ToolTipLine,
} from '@components';
import { colors } from '@theme';
import UploadBudgetImage from '@assets/upload_budget.svg';
import CreateBudgetImage from '@assets/create_budget.svg';
import { WithPermission } from '@utils';
import * as Controller from './controller';
import { DeleteIcon } from '@svgAsComponents';
import { CSVSampleLinks, CSVSampleLinksNames } from '@constants';
import { useLaunchDarklyFlags } from '@context';

const Budget = () => {
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();
  const controller = Controller.useProjectMilestones(projectId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="milestones" />;
    }

    case HookState.SUCCESS: {
      if (controller.createMode) return <NoBudgetContent controller={controller} />;
      return (
        <>
          {flags?.['ENG_9259_loan_budget_summary'] && <BudgetSummary />}
          <Milestones controller={controller} />
        </>
      );
    }

    default:
      return null;
  }
};

const NoBudgetContent = ({ controller }) => {
  const { editBudget, dynamicColumns, isOwner } = controller;

  return (
    <WithPermission
      permissionKey={[
        PermissionNamesEnums.PROJECT_ONBOARDING,
        PermissionNamesEnums.PROJECT_BUDGET_EDIT,
      ]}
    >
      <Stack mb={2}>
        <IsProductionBuildCheckbox checked={false} />
      </Stack>
      <Stack
        direction="row"
        sx={{ flexGrow: 1, backgroundColor: colors.background.white, padding: 3 }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={6} sx={{ flexGrow: 1 }}>
          <img
            src={UploadBudgetImage}
            style={{ maxWidth: '100%', width: '246px' }}
            alt="budget upload"
          />
          <CSVUploader
            buttonTitle="Upload budget"
            sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT_BUDGET}
            dynamicColumns={dynamicColumns}
            templateUrl={isOwner ? CSVSampleLinks.BUDGET_UPLOAD : null}
            templateName={isOwner ? CSVSampleLinksNames.BUDGET_UPLOAD : ''}
            source="upload_budget_button"
          />
        </Stack>
        <Stack spacing={3} alignItems="center">
          <Divider orientation="vertical" sx={{ height: '45%' }} />
          <Typography variant="body1">or</Typography>
          <Divider orientation="vertical" sx={{ height: '45%' }} />
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={6} sx={{ flexGrow: 1 }}>
          <img
            src={CreateBudgetImage}
            style={{ maxWidth: '100%', width: '246px' }}
            alt="budget creation"
          />
          <Button
            color="secondary"
            variant="new"
            size="small"
            onClick={editBudget}
            data-cy="create_budget_from_scratch_button"
          >
            Create budget from scratch
          </Button>
        </Stack>
      </Stack>
    </WithPermission>
  );
};

const Milestones: FC<{ controller: Controller.ControllerInterface }> = ({ controller }) => {
  const {
    initColumns,
    milestones,
    totals,
    handleFiltersChange,
    filterOptions,
    editBudget,
    editMode,
    addLineList,
    patchMilestone,
    showEdit,
    update,
    deleteBudgetModal,
    deleteBudget,
    deleteMilestone,
    filterValue,
    updateListData,
    showBudgetLockedWarning,
    isCreatingNewLine,
  } = controller;
  const flags = useLaunchDarklyFlags();
  return (
    <>
      {showBudgetLockedWarning && (
        <Stack direction="row" mb={2}>
          <ToolTipLine
            variant={ToolTipLineVariantEnum.LOCK}
            withBackground
            typographyVariant="body2"
            sx={{
              minHeight: '56px',
              borderRadius: '4px',
              flexGrow: 1,
              justifyContent: 'flex-start',
            }}
            fullWidth
          >
            <Typography variant="body2" sx={{ color: colors.status.information.medium }}>
              Budget is in the process of review by lender and cannot be modified.
            </Typography>
          </ToolTipLine>
        </Stack>
      )}

      <Stack
        sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
      >
        {flags?.[`ENG_7895_table_v3__${TableKeyEnum.LINE_ITEMS}`] ? (
          <MilestoneListV2
            rowReordering
            withoutStickLeftColumn
            updateData={updateListData}
            deleteMilestone={deleteMilestone}
            refetch={update}
            patchMilestone={patchMilestone}
            withColumnIndication
            tableKey={TableKeyEnum.LINE_ITEMS}
            initColumns={initColumns}
            milestones={milestones}
            totals={totals}
            key={`listWithEdit${editMode}`}
            exportToCSV
            headerRightPart={
              editBudget
                ? [
                    {
                      Component: (
                        <WithPermission
                          permissionKey={[
                            PermissionNamesEnums.PROJECT_ONBOARDING,
                            PermissionNamesEnums.PROJECT_BUDGET_EDIT,
                          ]}
                        >
                          <Stack spacing={1} direction="row">
                            {editMode && (
                              <LoadingButton
                                loading={isCreatingNewLine}
                                size="small"
                                onClick={addLineList}
                                data-cy="add_new_line_item_button"
                              >
                                Add line item
                              </LoadingButton>
                            )}
                          </Stack>
                        </WithPermission>
                      ),
                    },
                  ]
                : []
            }
            headerLeftPart={[
              {
                Component: (
                  <Filter
                    filterLabel="Show"
                    onChangeCallback={handleFiltersChange}
                    options={filterOptions}
                    filterValue={filterValue}
                    source="project__budget__line_items_table__filter"
                  />
                ),
              },
            ]}
            menuItems={
              Boolean(milestones?.length) && showEdit
                ? [
                    {
                      action: deleteBudgetModal.askConfirm,
                      text: 'Delete budget',
                      icon: <DeleteIcon size={15} />,
                      dataTestName: 'delete_budget__menu_item',
                    },
                  ]
                : []
            }
            source="project__budget__line_items_table"
          />
        ) : (
          <MilestoneList
            withoutStickLeftColumn
            updateData={updateListData}
            deleteMilestone={deleteMilestone}
            refetch={update}
            patchMilestone={patchMilestone}
            withColumnIndication
            tableKey={TableKeyEnum.LINE_ITEMS}
            initColumns={initColumns}
            milestones={milestones}
            totals={totals}
            key={`listWithEdit${editMode}`}
            exportToCSV
            headerRightPart={
              editBudget
                ? [
                    {
                      Component: (
                        <WithPermission
                          permissionKey={[
                            PermissionNamesEnums.PROJECT_ONBOARDING,
                            PermissionNamesEnums.PROJECT_BUDGET_EDIT,
                          ]}
                        >
                          <Stack spacing={1} direction="row">
                            {editMode && (
                              <LoadingButton
                                loading={isCreatingNewLine}
                                size="small"
                                onClick={addLineList}
                                data-cy="add_new_line_item_button"
                              >
                                Add line item
                              </LoadingButton>
                            )}
                          </Stack>
                        </WithPermission>
                      ),
                    },
                  ]
                : []
            }
            headerLeftPart={[
              {
                Component: (
                  <Filter
                    filterLabel="Show"
                    onChangeCallback={handleFiltersChange}
                    options={filterOptions}
                    filterValue={filterValue}
                    source="project__budget__line_items_table__filter"
                  />
                ),
              },
            ]}
            menuItems={
              Boolean(milestones?.length) && showEdit
                ? [
                    {
                      action: deleteBudgetModal.askConfirm,
                      text: 'Delete budget',
                      icon: <DeleteIcon size={15} />,
                      dataTestName: 'delete_budget__menu_item',
                    },
                  ]
                : []
            }
            source="project__budget__line_items_table"
          />
        )}
        <ConfirmationModal
          open={deleteBudgetModal.isConfirmModalOpened}
          title="Delete budget"
          text="Are you sure you want to delete all line items? If you delete, you will lose the data you entered."
          onClose={deleteBudgetModal.closeConfirmModal}
          confirmCallback={deleteBudget}
          type={PopupTypeEnum.ERROR}
        />
      </Stack>
    </>
  );
};

export default Budget;
