import { useUpdateUiSettings, useUrlParams } from '@hooks';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ComponentProps,
  DEFAULT_SET_TO_SETTINGS,
  DEFAULT_SET_TO_URL,
  DEFAULT_FILTERS_COUNT,
  FiltersTypes,
} from './interface';
import * as FiltersItems from './filters';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  deserializeFilters,
  getTeamRole,
  isRestricted,
  serializeFilters,
  toggleElementFromArray,
} from '@utils';
import { useDebouncedEffect } from '@models';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';
import { IMenuItem } from '@interfaces';

const getHiddedFiltersByData = (
  filters: FiltersTypes[],
  persisData: Record<string, string[]>,
  withLimit: boolean,
) => {
  const frl = (filters || []).filter((filter, index) => {
    const filterConfig = FiltersItems[filter];
    return withLimit
      ? index >= DEFAULT_FILTERS_COUNT && !persisData[filterConfig?.filterKey]
      : !persisData[filterConfig?.filterKey];
  });

  return frl;
};

export const useFiltersV2 = ({
  filters,
  setFilterStringQuery,
  tableKey,
  onPageChange,
  onReady,
}: ComponentProps) => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);
  const hiddenInited = useRef<boolean>(false);

  const filterTabKey = useMemo(() => `filter-${tableKey}`, [tableKey]);
  const filterTabKeyVisibility = useMemo(() => `filter-visibility-${tableKey}`, [tableKey]);

  const defaultFilterlData = useMemo(() => {
    const data: Record<string, Array<string>> = {};
    filters.forEach((item) => {
      const filterConfig = FiltersItems[item];
      const defaultFilters = filterConfig.defaultFilters?.(teamRole);
      if (defaultFilters?.length) {
        data[filterConfig.filterKey] = defaultFilters;
      }
    });
    return data;
  }, [filters, teamRole]);

  const userSettingsData = useMemo(
    () => settings.personal_setting?.[filterTabKey],
    [settings, filterTabKey],
  );

  const [filtersData, setData, onSetValue] = useUrlParams(
    userSettingsData || defaultFilterlData,
    'filters',
    serializeFilters,
    deserializeFilters,
    true,
  );

  const availableFilters = useMemo(
    () =>
      filters.filter((item) => {
        const filterConfig = FiltersItems[item];
        if (
          !filterConfig ||
          (filterConfig.permissionKey && isRestricted(filterConfig.permissionKey, permissions)) ||
          filterConfig.noNeedToRender
        )
          return false;
        if (filterConfig.userPermission) return filterConfig.userPermission(user);
        return true;
      }),
    [user, permissions, filters],
  );

  const [hiddenFilters, setHiddenFilters] = useState(
    getHiddedFiltersByData([...filters], filtersData, true),
  );

  const handleFiltersChange = useCallback(
    (key: string, value: string[], skipSettings?: boolean) => {
      const filterConfig = Object.values(FiltersItems).find((item) => item.filterKey === key);
      const needToUpdateUrl = filterConfig?.needToUpdateUrl || DEFAULT_SET_TO_URL;
      const needToUpdateSetting = filterConfig?.needToUpdateSetting || DEFAULT_SET_TO_SETTINGS;

      onSetValue({ [key]: value }, !needToUpdateUrl);
      if (needToUpdateSetting && !skipSettings)
        updateSettings({
          personal_setting: {
            ...settings.personal_setting,
            [filterTabKey]: {
              ...(settings.personal_setting?.[filterTabKey] || {}),
              [key]: value,
            },
          },
        });
    },
    [filtersData, settings],
  );

  const toggleHiddenField = useCallback(
    (item) => {
      setHiddenFilters((data) => {
        const newFilters = toggleElementFromArray(data, item);
        const needToClear = !data.includes(item);

        updateSettings({
          personal_setting: {
            ...settings.personal_setting,
            [filterTabKeyVisibility]: newFilters,
          },
        });
        if (needToClear) {
          const filterConfig = FiltersItems[item];
          onSetValue({ [filterConfig.filterKey]: [] });
        }
        return newFilters;
      });
    },
    [filterTabKeyVisibility, updateSettings],
  );

  const availableFiltersMenuItems = useMemo<IMenuItem[]>(
    () =>
      [...availableFilters].map((item, index) => {
        const filterConfig = FiltersItems[item];
        return {
          disabled: index < DEFAULT_FILTERS_COUNT,
          action: () => toggleHiddenField(item),
          textTypographyProps: {
            variant: 'body3',
          },
          text: filterConfig.title,
          icon: hiddenFilters.includes(item) ? <CheckboxIconDefault /> : <CheckboxIconChecked />,
          dataTestName: `available_filters__${filterConfig.title}`,
        };
      }),
    [hiddenFilters, availableFilters.length],
  );

  const needToCollapse = useMemo(
    () => availableFilters.length > DEFAULT_FILTERS_COUNT,
    [availableFilters],
  );

  useEffect(() => {
    if (userSettingsData && !hiddenInited.current) {
      setHiddenFilters(
        getHiddedFiltersByData(
          settings?.personal_setting?.[filterTabKeyVisibility],
          filtersData,
          false,
        ),
      );
      hiddenInited.current = true;
    }
  }, [settings?.personal_setting]);

  const updateFilters = useCallback(() => {
    const string = serializeFilters(filtersData);
    setFilterStringQuery(string);
    onPageChange?.(null, 0);
  }, [filtersData]);

  useDebouncedEffect(
    () => {
      updateFilters();
    },
    [filtersData],
    500,
  );

  const resetFiltersToDefault = useCallback(() => {
    setData(defaultFilterlData);
    setHiddenFilters([...availableFilters].slice(DEFAULT_FILTERS_COUNT));
    updateSettings({
      personal_setting: {
        ...settings.personal_setting,
        [filterTabKeyVisibility]: [...availableFilters].slice(DEFAULT_FILTERS_COUNT),
        [filterTabKey]: {
          ...defaultFilterlData,
        },
      },
    });
  }, [settings, defaultFilterlData, availableFilters]);

  useEffect(() => {
    updateFilters();
    onReady?.();
  }, []);

  return {
    filtersData,
    handleFiltersChange,
    availableFilters,
    resetFiltersToDefault,
    hiddenFilters,
    availableFiltersMenuItems,
    needToCollapse,
  };
};
