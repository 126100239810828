import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  StringFieldModel,
  useStringFieldModel,
  usePhoneFieldModel,
  PhoneFieldModel,
} from '@models';
import { addUserToTeams, getCompanyTeams, findUserByEmail } from '@globalService';
import {
  AddUserToTeamsPayload,
  EnumTypeForList,
  ErrorDual,
  ITeam,
  IUser,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { checkIsOwner, getErrorText, getTeamRole, isRestricted, regexValidation } from '@utils';
import { AuthContext, PermissionsContext } from '@context';
import { useSafeSnackbar } from '@hooks';

export type ControllerInterface = {
  email: StringFieldModel;
  firstName: StringFieldModel;
  lastName: StringFieldModel;
  phone: PhoneFieldModel;
  handleAddCompanyUser: () => void;
  isSubmitting: boolean;
  teams: EnumTypeForList[];
  optionsLoading: boolean;
  isOpened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseCallback: () => void;
  selectedTeams: EnumTypeForList[];
  setSelectedTeams: Dispatch<SetStateAction<EnumTypeForList[]>>;
  inviteDisabled: boolean;
  isEditUsersEnabled: boolean;
  isSearching: boolean;
  handleSearchEmailClick: () => void;
  foundUser: IUser | null;
  tooltipText: string;
  showAdditionalInputs: boolean;
};

export const useAddCompanyUser = ({ companyId }): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { companyId: paramCompanyId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  // this query param is used to determine if response should contains
  // all company teams or only teams that has shared projects with current user company
  // to implement independent borrower teams for different lenders concept
  const is_on_shared_project = useMemo(() => Boolean(companyId), [companyId]);
  const [isOpened, setOpened] = useState(false);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_TEAMS,
      { companyId: paramCompanyId || companyId, is_on_shared_project },
    ],
    getCompanyTeams.bind(this, paramCompanyId || companyId, is_on_shared_project),
    { enabled: Boolean(paramCompanyId || companyId) },
  );

  const [selectedTeams, setSelectedTeams] = useState<EnumTypeForList[]>([]);

  const [isSearching, setIsSearching] = useState(false);
  const [foundUser, setFoundUser] = useState<IUser | null>(null);
  const [tooltipText, setTooltipText] = useState<string>('');
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);

  const email = useStringFieldModel({
    initValue: '',
    validationRule: (value) => regexValidation('email', value) && Boolean(value?.trim()),
    validateOnChange: true,
  });

  const firstName = useStringFieldModel({
    initValue: '',
  });
  const lastName = useStringFieldModel({
    initValue: '',
  });
  const phone = usePhoneFieldModel({
    initValue: '',
  });

  const addCompanyUserToSeveralTeamsMutation = useMutation<
    Response,
    ErrorDual,
    AddUserToTeamsPayload
  >(addUserToTeams, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_USERS]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES]);
      onCloseCallback();
    },
    onError: (error: ErrorDual) => {
      enqueueSnackbar(getErrorText(error), {
        variant: 'error',
      });
    },
  });

  const handleAddCompanyUser = useCallback(() => {
    const isTeamValid = selectedTeams.length > 0;
    const isEmailValid = email.validate();
    const isPhoneValid = phone.validate();

    const isAllFieldsValid = isTeamValid && isEmailValid && isPhoneValid;
    if (!isAllFieldsValid) return;

    addCompanyUserToSeveralTeamsMutation.mutateAsync({
      companyId: companyId || paramCompanyId,
      data: {
        teams: selectedTeams.map((o) => o.id),
        user: {
          ...(foundUser
            ? { id: foundUser.id }
            : {
                email: email.value,
                first_name: firstName.value,
                last_name: lastName.value,
                phone: phone.valueToSave,
              }),
        },
      },
    });
  }, [paramCompanyId, email, selectedTeams, firstName, lastName, phone, companyId, foundUser]);

  const teams = useMemo(
    () =>
      companyTeamsQuery.data?.results?.map((o) => ({
        id: o.id,
        name_display: o.name,
        name: o.id,
      })) || [],
    [companyTeamsQuery.data],
  );

  const setInitialValues = useCallback(() => {
    if (teams?.length === 1 || (checkIsOwner(teamRole) && teams?.length > 1)) {
      setSelectedTeams(teams);
    } else if (teams?.length) {
      setSelectedTeams([teams[0]]);
    }

    firstName.setValue('');
    lastName.setValue('');
    phone.setValue('');
    setShowAdditionalInputs(false);
    setFoundUser(null);
    setTooltipText('');
  }, [teams]);

  useEffect(() => {
    setInitialValues();

    return () => {
      setSelectedTeams([]);
    };
  }, [teams, email.value]);

  const onCloseCallback = useCallback(() => {
    setInitialValues();
    setOpened(false);
  }, []);

  const isEditUsersEnabled = useMemo(
    () =>
      companyId
        ? !isRestricted(PermissionNamesEnums.COMPANY_USERS_EDIT, permissions) ||
          !isRestricted(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions)
        : !isRestricted(PermissionNamesEnums.PROJECT_ONBOARDING, permissions),
    [permissions],
  );

  const findUserMutation = useMutation<{ results: IUser[] }, ErrorDual, string>(findUserByEmail, {
    onSuccess: (data) => {
      if (data && data?.results?.length > 0) {
        const user = data.results[0];
        setFoundUser(user);
        firstName.setValue(user.first_name);
        lastName.setValue(user.last_name);
        phone.setValue(user.phone || '');
        setTooltipText('User found. The user will be added to the selected teams.');
      } else {
        setFoundUser(null);
        setTooltipText('User not found. Please enter the details below to create a new user.');
      }
      setShowAdditionalInputs(true);
    },
    onError: (error) => {
      enqueueSnackbar(getErrorText(error), {
        variant: 'error',
      });
      setFoundUser(null);
      setTooltipText('An error occurred while searching for the user.');
      setShowAdditionalInputs(false);
    },
    onSettled: () => {
      setIsSearching(false);
    },
  });

  const handleSearchEmailClick = useCallback(() => {
    if (!email.value?.trim()) return;
    setIsSearching(true);
    findUserMutation.mutateAsync(email.value);
  }, [email.value]);

  return {
    email,
    firstName,
    lastName,
    phone,
    handleAddCompanyUser,
    isSubmitting: addCompanyUserToSeveralTeamsMutation.isLoading,
    teams,
    optionsLoading: companyTeamsQuery.isLoading,
    isOpened,
    setOpened,
    onCloseCallback,
    selectedTeams,
    setSelectedTeams,
    inviteDisabled: !teams?.length,
    isEditUsersEnabled,
    isSearching,
    handleSearchEmailClick,
    foundUser,
    tooltipText,
    showAdditionalInputs,
  };
};
