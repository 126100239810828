import React, { FC, Fragment } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  Skeleton,
  Stack,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { HookState, IProjectComment, MessagePanelTabsEnum } from '@interfaces';
import { colors } from '@theme';
import * as Controller from './controller';
import {
  ButtonWithTooltipOnClick,
  CommentHeader,
  Loader,
  SearchBox,
  ServiceMessage,
  Tags,
  WysiwygEditor,
  TabSwitcher,
  TablePaginationNew,
  SortingIconWithPopper,
} from '@components';
import { PinIcon, ArrowRightTurnIcon } from '@svgAsComponents';
import { TOOLTIP_TEXTS } from '@constants';
import { TABS } from './utils';
import CollapsibleComment from './CollapsibleComment';

const MessagePanel: FC<{
  projectId?: string;
  requestId?: string;
  milestoneId?: string;
  inspectionId?: string;
  serviceOrderId?: string;
  documentId?: string;
  source: string;
  tab?: MessagePanelTabsEnum;
  showTabs?: boolean;
  includeChildren?: boolean;
}> = ({
  projectId,
  requestId,
  milestoneId,
  inspectionId,
  serviceOrderId,
  documentId,
  source,
  tab = MessagePanelTabsEnum.INTERNAL,
  showTabs = true,
  includeChildren = true,
}) => {
  const {
    state,
    postMessage,
    commentField,
    replyField,
    comments,
    handleSearchSubmit,
    clearSearch,
    search,
    commentsAreLoading,
    isPostingComment,
    tags,
    reportTag,
    setReportTag,
    updateComment,
    showReportTag,
    showSendToInspectorCheckbox,
    isSendToInspectorChecked,
    setIsSendToInspectorChecked,
    pinComment,
    permittedTabs,
    handleTabChange,
    activeTab,
    replyMode,
    handleReplyClick,
    parentComment,
    handleCloseReplyMode,
    expandedCommentId,
    handleExpandThreadClick,
    thread,
    threadIsLoading,
    hideCommentInput,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    commentsCount,
    isCurrentProjectArchived,
    handleEditCommentClick,
    editCommentField,
    editedComment,
    saveEditedComment,
    isExternalComment,
    sortingOptions,
    sortingValue,
  } = Controller.useProjectComments({
    projectId,
    requestId,
    milestoneId,
    inspectionId,
    serviceOrderId,
    documentId,
    isProjectComments: !requestId,
    tab,
    includeChildren,
  });
  const renderTabs = () => (
    <TabSwitcher
      tabs={permittedTabs}
      onTabChange={handleTabChange}
      activeTab={activeTab}
      source={source}
      isSmall
      tabStyle={{ paddingLeft: 0, marginTop: 0 }}
    />
  );

  const renderCommentForm = () => (
    <>
      <Stack direction="row" sx={{ width: '100%', justifyContent: 'flex-start' }}>
        <Tags
          items={tags}
          reportTag={reportTag}
          showReportTag={showReportTag && tags.some((tag) => tag.includes('Draw'))}
          setReportTag={setReportTag}
          source={`${source}__header_tags`}
        />
      </Stack>
      <Stack sx={{ width: '100%', mt: 0.5 }}>
        <WysiwygEditor
          editField={commentField}
          source={source}
          isExternalComment={isExternalComment}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ mb: 3 }}
        >
          {showSendToInspectorCheckbox && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSendToInspectorChecked}
                  onChange={(_e, value) => setIsSendToInspectorChecked(value)}
                  data-cy={`${source}__send_to_inspector__checkbox`}
                  data-tour="send_to_inspector__checkbox"
                />
              }
              label={<Typography variant="body3">Send to inspection agency</Typography>}
            />
          )}
          <ButtonWithTooltipOnClick
            sx={{ width: '100px', height: '40px', alignSelf: 'flex-end', mt: 1 }}
            onClick={() => commentField.validate() && postMessage(commentField.value)}
            conditionallyDisabled={!commentField.value || isCurrentProjectArchived}
            disabled={isPostingComment || replyMode}
            tooltipText={
              replyMode
                ? 'Reply is active'
                : isCurrentProjectArchived
                  ? TOOLTIP_TEXTS.nonActiveProject
                  : 'Comment is empty'
            }
            dataTestName={`${source}__send__button`}
            data-value={commentField.value}
          >
            {isPostingComment ? 'Commenting' : 'Comment'}
          </ButtonWithTooltipOnClick>
        </Stack>
      </Stack>
    </>
  );

  const renderSearchBox = () => (
    <Stack
      sx={{ mb: '24px' }}
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={1}
    >
      {!milestoneId && !documentId && (
        <Stack sx={{ flex: 1 }}>
          <SearchBox
            search={search}
            onSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            dataTestName={`${source}__search__input__${activeTab}_tab`}
            height="32px"
          />
        </Stack>
      )}

      {!!commentsCount && (
        <Stack sx={{ flex: 1, minWidth: '200px' }} alignItems="flex-end">
          {renderPagination()}
        </Stack>
      )}
      {commentsCount > 1 && (
        <SortingIconWithPopper
          value={sortingValue}
          menuItems={sortingOptions}
          cypressSelector={`${source}__sorting_by_date`}
        />
      )}
    </Stack>
  );

  const renderComments = () =>
    comments?.map((comment, index) => {
      const tags = comment.tags.filter((tag) => tag !== 'Report');
      const reportTag = comment.tags.includes('Report');
      const showTagForReport = showReportTag && comment.tags.some((tag) => tag.includes('Draw'));
      const isCommentInReplyMode = comment.id === parentComment?.id && replyMode;
      const isCommentThreadExpanded = comment.id === expandedCommentId;
      const isCommentEdited = editedComment?.id === comment.id;

      if (!comment.message) return null;
      return (
        <Fragment key={comment.id}>
          {renderCommentBody({
            comment,
            isCommentEdited,
            showRecipient: activeTab === TABS.ALL.value,
          })}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="flex-start">
              <Tooltip title={comment.is_pinned ? 'Unpin' : 'Pin'}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    minWidth: 24,
                    height: 24,
                    backgroundColor: comment.is_pinned
                      ? colors.status.information.light
                      : colors.neutral.lightest,
                    mr: 1,
                    cursor: 'pointer',
                  }}
                  onClick={pinComment(comment)}
                  data-cy={`${source}__pin_comment__icon__${activeTab}_tab`}
                >
                  <PinIcon
                    size={16}
                    color={comment.is_pinned ? colors.status.information.medium : colors.icons.gray}
                  />
                </Stack>
              </Tooltip>

              <Tags
                items={tags}
                reportTag={reportTag}
                showReportTag={showTagForReport}
                setReportTag={updateComment(comment)}
                source={`${source}__comment`}
              />
            </Stack>
            {!isCommentEdited && (
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                {comment.has_children && (
                  <LoadingButton
                    variant="text"
                    size="small"
                    sx={{ minWidth: 125 }}
                    onClick={handleExpandThreadClick(comment.id)}
                    loading={isCommentThreadExpanded && threadIsLoading}
                    data-cy={`${source}__thread_button__${isCommentThreadExpanded ? 'collapse' : 'expand'}__${activeTab}_tab`}
                  >
                    {isCommentThreadExpanded ? 'Collapse thread' : 'Expand thread'}
                  </LoadingButton>
                )}
                {!isCommentInReplyMode &&
                  !comment?.is_external_comment && ( // not to show edit button for automated inspection comments
                    <ButtonWithTooltipOnClick
                      color="secondary"
                      variant="new"
                      size="small"
                      onClick={handleReplyClick({ comment })}
                      dataTestName={`${source}__reply__button__${activeTab}_tab`}
                      conditionallyDisabled={isCurrentProjectArchived}
                      tooltipText={isCurrentProjectArchived ? TOOLTIP_TEXTS.nonActiveProject : ''}
                    >
                      Reply
                    </ButtonWithTooltipOnClick>
                  )}
              </Stack>
            )}
          </Stack>
          {isCommentThreadExpanded &&
            thread?.map((threadComment) => {
              const isThreadCommentEdited = editedComment?.id === threadComment.id;

              return (
                <Stack
                  direction="row"
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  sx={{ mt: 2 }}
                  key={threadComment.id}
                >
                  <Stack justifyContent="center" alignItems="center" sx={{ pr: 2, pt: 1 }}>
                    <ArrowRightTurnIcon />
                  </Stack>
                  <Stack sx={{ flex: 1 }} spacing={2}>
                    {renderCommentBody({
                      comment: threadComment,
                      isCommentEdited: isThreadCommentEdited,
                      showRecipient: false,
                    })}
                  </Stack>
                </Stack>
              );
            })}
          {isCommentInReplyMode && (
            <Stack direction="row" flexWrap="nowrap" alignItems="flex-start" sx={{ mt: 2 }}>
              <Stack justifyContent="center" alignItems="center" sx={{ pr: 2, pt: 1 }}>
                <ArrowRightTurnIcon />
              </Stack>
              <Stack sx={{ flex: 1 }} spacing={2}>
                <WysiwygEditor
                  editField={replyField}
                  source={source}
                  isExternalComment={isExternalComment}
                />
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                  <Button
                    variant="text"
                    size="small"
                    sx={{ minWidth: 117 }}
                    onClick={handleCloseReplyMode}
                    data-cy={`${source}__cancel_reply__button`}
                  >
                    Cancel
                  </Button>
                  <ButtonWithTooltipOnClick
                    onClick={() => replyField.validate() && postMessage(replyField.value)}
                    conditionallyDisabled={!replyField.value}
                    disabled={isPostingComment}
                    tooltipText={'Comment is empty'}
                    dataTestName={`${source}__send_reply__button__${activeTab}_tab`}
                    size="small"
                  >
                    {isPostingComment ? 'Commenting' : 'Comment'}
                  </ButtonWithTooltipOnClick>
                </Stack>
              </Stack>
            </Stack>
          )}
          {index !== comments.length - 1 && (
            <Divider sx={{ mt: 2, mb: 3, color: colors.neutral.lighter }} />
          )}
        </Fragment>
      );
    });

  const renderLoader = () => (
    <Grid2 container justifyContent="center">
      <Loader />
    </Grid2>
  );

  const renderNoResultsMessage = () => (
    <Typography variant="body3">No results for selected params</Typography>
  );

  const renderCommentBody = ({
    comment,
    isCommentEdited,
    showRecipient,
  }: {
    comment: IProjectComment;
    isCommentEdited: boolean;
    showRecipient: boolean;
  }) => (
    <Stack>
      <CommentHeader
        comment={comment}
        source={source}
        showRecipient={showRecipient}
        projectId={projectId}
        handleEditCommentClick={handleEditCommentClick}
      />
      {isCommentEdited && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          <WysiwygEditor
            editField={editCommentField}
            source={source}
            isExternalComment={isExternalComment}
          />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="text"
              size="small"
              onClick={handleEditCommentClick(null)}
              data-cy={`${source}__cancel_edit_comment__button__${activeTab}_tab`}
            >
              Cancel
            </Button>
            <ButtonWithTooltipOnClick
              onClick={saveEditedComment}
              conditionallyDisabled={!editCommentField.value}
              disabled={isPostingComment}
              tooltipText={'Comment is empty'}
              dataTestName={`${source}__save_edited_comment__button__${activeTab}_tab`}
              size="small"
            >
              {isPostingComment ? 'Saving' : 'Save'}
            </ButtonWithTooltipOnClick>
          </Stack>
        </Stack>
      )}

      {!isCommentEdited && <CollapsibleComment message={comment.message} source={source} />}
    </Stack>
  );

  const renderPagination = () => (
    <TablePaginationNew
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      itemsCount={commentsCount}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      source={source}
    />
  );

  if (state === HookState.FETCHING) {
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height={24} sx={{ mt: '2rem' }} />
        <Skeleton variant="rectangular" width="100%" height={100} sx={{ mt: '1.5rem' }} />
      </>
    );
  }

  if (state === HookState.ERROR) {
    return <ServiceMessage text="Message panel" />;
  }

  return (
    <>
      {showTabs && permittedTabs?.length > 1 && renderTabs()}
      {!hideCommentInput && renderCommentForm()}
      {renderSearchBox()}
      {Boolean(search) && !commentsAreLoading && !comments?.length && renderNoResultsMessage()}
      {commentsAreLoading && renderLoader()}
      {renderComments()}
      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        {renderPagination()}
      </Stack>
    </>
  );
};

export default MessagePanel;
