import { isMobileOnly } from 'react-device-detect';

const commonScreens = [
  '/profile',
  '/requests',
  '/projects',
  '/projects/:projectId',
  '/company',
  '/home',
  '/services',
];

const defaultSceenACL = {
  available: ['/profile', '/projects', '/projects/:projectId', '/company'],
  default: '/projects',
};

export const USER_ROLES = {
  Regular: 'REGULAR',
  Reviewer: 'REVIEWER',
  CustomerSuccess: 'CUSTOMER_SUCCESS',
  Superuser: 'SUPERUSER',
  Admin: 'ADMIN',
};

export const TEAM_ROLES = {
  Lender: 'LENDER',
  GeneralContractor: 'GENERAL_CONTRACTOR',
  Subcontractor: 'SUBCONTRACTOR',
  Owner: 'OWNER',
  Reviewer: 'REVIEWER',
  CustomerSuccess: 'CUSTOMER_SUCCESS',
  Superuser: 'SUPERUSER',
  Admin: 'ADMIN',
  Investor: 'INVESTOR',
};

// map screens to team role
export const screensACL = isMobileOnly
  ? {
      [TEAM_ROLES.Lender]: defaultSceenACL,
      [TEAM_ROLES.Investor]: defaultSceenACL,
      [TEAM_ROLES.Owner]: defaultSceenACL,
      [TEAM_ROLES.GeneralContractor]: defaultSceenACL,
      [TEAM_ROLES.Admin]: defaultSceenACL,
      [TEAM_ROLES.CustomerSuccess]: defaultSceenACL,
      [TEAM_ROLES.Superuser]: defaultSceenACL,
    }
  : {
      [TEAM_ROLES.Lender]: {
        available: [...commonScreens, '/analytics', '/services'],
        default: '/requests',
      },
      [TEAM_ROLES.Investor]: {
        available: [...commonScreens, '/analytics', '/services'],
        default: '/requests',
      },
      [USER_ROLES.Admin]: {
        available: commonScreens,
        default: '/requests',
      },
      [TEAM_ROLES.Owner]: {
        available: [...commonScreens, '/analytics'],
        default: '/projects',
      },
      [TEAM_ROLES.GeneralContractor]: {
        available: commonScreens,
        default: '/projects',
      },
      [TEAM_ROLES.CustomerSuccess]: {
        available: [...commonScreens, '/platform-settings'],
        default: '/requests',
      },
      [USER_ROLES.Superuser]: {
        available: commonScreens,
        default: '/requests',
      },
    };
