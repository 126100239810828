import React, { useContext, useMemo } from 'react';
import { Link, matchPath, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';

import CompanyInfo from './CompanyInfo';
import CompanyUsers from './CompanyUsers';
import CompanyPolicies from './CompanyPolicies';
import CompanyTeams from './CompanyTeams';
import CompanyWorkflow from './CompanyWorkflow';
import CompanyWorkflowV2 from './CompanyWorkflowV2';
import CompanySecurity from './CompanySecurity';
import CompanyOtherSettings from './CompanyOtherSettings';
import { colors } from '@theme';
import { PermissionNamesEnums } from '@interfaces';
import { isRestricted } from '@utils';
import { PermissionsContext, useLaunchDarklyFlags, AuthContext } from '@context';
import { PermissionDenied, RolesPermisssion } from '@components';

const TABS = {
  GENERAL: { label: 'General', value: 'general' },
  USERS: { label: 'Users', value: 'users' },
  TEAMS: { label: 'Teams', value: 'teams' },
  WORKFLOW: { label: 'Workflow', value: 'workflow' },
  POLICIES: { label: 'Policies', value: 'policies' },
  SECURITY: { label: 'Security', value: 'security' },
  OTHER: { label: 'Other', value: 'other' },
};

const Company = () => {
  const { pathname } = useLocation();
  const { companyId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const match = matchPath('/company/:companyId/:tab/*', pathname);
  const pathBase = `/company/${companyId}`;
  const flags = useLaunchDarklyFlags();
  const hasPoliciesViewPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMPANY_POLICIES_VIEW, permissions),
    [permissions],
  );
  const hasCompanyTeamsViewPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMPANY_TEAMS_VIEW, permissions),
    [permissions],
  );
  const hasCompanyEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMPANY_EDIT, permissions),
    [permissions],
  );

  if (user?.company_id !== companyId) return <PermissionDenied />;

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <Typography variant="h1" sx={{ px: { md: 3, xs: 2 }, pt: { md: 3, xs: 2 } }}>
        Organization settings
      </Typography>
      <Box sx={{ mt: { md: 1.5, xs: 0 }, position: 'relative' }}>
        <Tabs
          TabIndicatorProps={{
            style: { transition: 'none' },
            children: <span />,
          }}
          textColor="secondary"
          indicatorColor="secondary"
          value={match?.params.tab || 'general'}
        >
          <Tab
            label={TABS.GENERAL.label}
            value={TABS.GENERAL.value}
            component={Link}
            to={`${pathBase}/${TABS.GENERAL.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`company_tab_${TABS.GENERAL.value}`}
          />
          <Tab
            label={TABS.USERS.label}
            value={TABS.USERS.value}
            component={Link}
            to={`${pathBase}/${TABS.USERS.value}`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`company_tab_${TABS.USERS.value}`}
          />
          {hasCompanyTeamsViewPermission && (
            <Tab
              label={TABS.TEAMS.label}
              value={TABS.TEAMS.value}
              component={Link}
              to={`${pathBase}/${TABS.TEAMS.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`company_tab_${TABS.TEAMS.value}`}
            />
          )}
          {hasCompanyTeamsViewPermission && (
            <Tab
              label={TABS.WORKFLOW.label}
              value={TABS.WORKFLOW.value}
              component={Link}
              to={`${pathBase}/${TABS.WORKFLOW.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`company_tab_${TABS.WORKFLOW.value}`}
            />
          )}
          {hasPoliciesViewPermission && (
            <Tab
              label={TABS.POLICIES.label}
              value={TABS.POLICIES.value}
              component={Link}
              to={`${pathBase}/${TABS.POLICIES.value}/all`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`company_tab_${TABS.POLICIES.value}`}
            />
          )}
          {hasCompanyEditPermission && (
            <Tab
              label={TABS.SECURITY.label}
              value={TABS.SECURITY.value}
              component={Link}
              to={`${pathBase}/${TABS.SECURITY.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
              data-cy={`company_tab_${TABS.SECURITY.value}`}
            />
          )}
          {hasPoliciesViewPermission &&
            flags?.['ENG_9252_change_request_configuration_options'] && (
              <Tab
                label={TABS.OTHER.label}
                value={TABS.OTHER.value}
                component={Link}
                to={`${pathBase}/${TABS.OTHER.value}`}
                sx={{ ml: { md: 3, xs: 2 } }}
                data-cy={`company_tab_${TABS.OTHER.value}`}
              />
            )}
        </Tabs>
      </Box>
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: { md: 3, xs: 2 } }}>
        <Routes>
          <Route path={TABS.GENERAL.value} element={<CompanyInfo />} />
          <Route path={TABS.USERS.value} element={<CompanyUsers />} />
          <Route path={`${TABS.TEAMS.value}`} element={<CompanyTeams />} />
          <Route
            path={`${TABS.WORKFLOW.value}`}
            element={
              flags?.['ENG_8495_new_approval_flow'] ? <CompanyWorkflowV2 /> : <CompanyWorkflow />
            }
          />
          <Route path={`roles/:roleId/permissions`} element={<RolesPermisssion />} />
          <Route path={`${TABS.POLICIES.value}/:tab`} element={<CompanyPolicies />} />
          <Route path={TABS.SECURITY.value} element={<CompanySecurity />} />
          <Route path={TABS.OTHER.value} element={<CompanyOtherSettings />} />
        </Routes>
      </Stack>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.GENERAL.value}`} replace />
  );
};

export default Company;
