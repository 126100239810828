import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'date-fns';
import get from 'lodash/get';
import { GridEventListener, GridSortModel } from '@mui/x-data-grid';

import {
  findLastCompletedInspectionDate,
  getHookState,
  getTeamRole,
  isCompletedService,
  tableHeaders as headers,
  getLink,
  convertReactTableColumnsToMuiDataGridColumns,
  isRequestInReview,
  getSortStringByGridSortModel,
  isRestricted,
} from '@utils';
import {
  IDrawRequest,
  IInspection,
  IProject,
  IServiceOrder,
  QueryNamesEnums,
  TableKeyEnum,
  IRightDrawerParams,
  ServiceOrderStatusEnum,
  PermissionNamesEnums,
} from '@interfaces';
import { AuthContext, SettingsContext, PermissionsContext } from '@context';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useRightMenu,
  useTablePagination,
} from '@hooks';
import {
  getDrawRequest,
  getDrawRequestInspectionsList,
  getProject,
  getProjectInspectionsList,
  getProjectServiceOrdersList,
  getDrawRequestServiceOrdersList,
} from '@globalService';
import { ControllerInterface } from './interface';

const TABS = {
  documents: { label: 'Documents', value: 'documents' },
  comments: { label: 'Comments', value: 'comments' },
};

export const useServices = (projectId: string, drawRequestId: string): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { settings } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );
  const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([]);

  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination({});

  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const params = {
    pagination: 'true',
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage.toString(),
    sorting: getSortStringByGridSortModel(tableSortingModel),
  };

  const queryParams = new URLSearchParams(params);
  let stringQueryParams = queryParams.toString();
  if (filterStringQuery) {
    stringQueryParams += `&${filterStringQuery}`;
  }
  stringQueryParams += `&excluded_status=${ServiceOrderStatusEnum.CREATED}`;

  const [tab, setTab] = useState(TABS.documents.value);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({
    inspectionId: '',
    serviceOrderId: '',
    requestId: drawRequestId,
    projectId,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    ...(rightDrawerParams.inspectionId ? { inspectionId: rightDrawerParams.inspectionId } : {}),
    ...(rightDrawerParams.serviceOrderId
      ? { serviceOrderId: rightDrawerParams.serviceOrderId }
      : {}),
  });

  const allInspectionsQuery = useQuery<{ results: IInspection[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId, stringQueryParams }],
    getProjectInspectionsList.bind(this, { projectId, stringQueryParams }),
    { enabled: Boolean(projectId) && !drawRequestId },
  );
  const allServiceOrdersQuery = useQuery<{ results: IServiceOrder[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId, stringQueryParams }],
    getProjectServiceOrdersList.bind(this, {
      projectId,
      stringQueryParams: stringQueryParams.replace('inspection_agency', 'service_agency'),
    }),
    { enabled: Boolean(projectId) && !drawRequestId },
  );

  const drawRequestInspectionsQuery = useQuery<{ results: IInspection[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, { projectId, drawRequestId, stringQueryParams }],
    getDrawRequestInspectionsList.bind(this, {
      projectId,
      drawRequestId,
      stringQueryParams,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const drawRequestServiceOrdersQuery = useQuery<{ results: IServiceOrder[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
      { projectId, drawRequestId, stringQueryParams },
    ],
    getDrawRequestServiceOrdersList.bind(this, {
      projectId,
      drawRequestId,
      stringQueryParams: stringQueryParams.replace('inspection_agency', 'service_agency'),
    }),
    { enabled: Boolean(drawRequestId) },
  );

  // DR to detect his status (active or not)
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: !!projectId },
  );

  const { pdf, gallery, close } = useImagePicker();

  const servicesList = useMemo(() => {
    const requestInspections = get(drawRequestInspectionsQuery, 'data.results', []);
    const requestServiceOrders = get(drawRequestServiceOrdersQuery, 'data.results', []);
    const projectInspections = get(allInspectionsQuery, 'data.results', []);
    const projectServiceOrders = get(allServiceOrdersQuery, 'data.results', []);

    if (
      !requestInspections.length &&
      !projectInspections.length &&
      !requestServiceOrders.length &&
      !projectServiceOrders.length
    )
      return [];

    return (
      drawRequestId
        ? [...requestInspections, ...requestServiceOrders]
        : [...projectInspections, ...projectServiceOrders]
    ).sort((a, b) => {
      const dateA = a.ordered_at || a.created_at;
      const dateB = b.ordered_at || b.created_at;
      return new Date(dateB).getTime() - new Date(dateA).getTime();
    });
  }, [
    drawRequestInspectionsQuery.data,
    drawRequestServiceOrdersQuery.data,
    allInspectionsQuery.data,
    allServiceOrdersQuery.data,
    drawRequestId,
    drawRequestQuery?.data,
  ]);

  const lastCompletedInspection = useMemo(() => {
    if (drawRequestId && !isRequestInReview(drawRequestQuery.data?.status)) return null;

    const inpectionsArr = drawRequestId
      ? drawRequestInspectionsQuery.data?.results
      : allInspectionsQuery.data?.results;

    const filteredArr = inpectionsArr?.filter(({ status }) => isCompletedService(status)) || [];
    const lastCompletedDate = findLastCompletedInspectionDate(filteredArr);

    return filteredArr.find(({ completed_at }) =>
      isEqual(new Date(completed_at), lastCompletedDate),
    );
  }, [
    drawRequestId,
    drawRequestInspectionsQuery.data,
    allInspectionsQuery.data,
    drawRequestQuery.data?.status,
  ]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const updateRightDrawer = ({ inspectionId, serviceOrderId, activeTab, title }) => {
    handleRightDrawerOpenerClick({
      title,
    });

    setRightDrawerParams((old) => ({ ...old, inspectionId, serviceOrderId, activeTab }));
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const link = getLink({
      row: params.row,
      tableKey: TableKeyEnum.SERVICES_LIST,
      lastCompletedInspection,
    });
    link && navigate(link, { state: location?.pathname });
  };

  const columns = useMemo(
    () =>
      convertReactTableColumnsToMuiDataGridColumns([
        headers.orderedServiceDate({
          header: 'Date ordered',
          disableSortBy: true,
        }),
        headers.serviceType({
          serviceTypesMap,
        }),
        headers.serviceStatus({}),
        headers.headerTemplate({
          disableSortBy: true,
          headerName: 'ID',
          accessor: 'service_number',
        }),
        headers.serviceAgencyColumn({
          teamRole,
          disableSortBy: true,
        }),
        headers.inspectionAgencyStatus({}),
        ...(!isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions)
          ? [
              headers.amount({
                accessor: 'invoiced_amount',
                header: 'Cost',
                placeholder: 'Pending',
                disableSortBy: true,
              }),
            ]
          : []),
        ...(!isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
          ? [
              headers.amount({
                accessor: 'provider_service_cost',
                header: 'Provider cost',
                disableSortBy: true,
              }),
            ]
          : []),
        headers.scheduledInspectionDate({
          accessor: 'scheduled_at',
          header: 'Date scheduled',
          disableSortBy: true,
        }),
        headers.date({
          accessor: 'completed_at',
          header: 'Date completed',
          disableSortBy: true,
        }),
        headers.headerTemplate({
          headerName: 'External Id',
          accessor: 'provider_order_id',
          disableSortBy: true,
        }),
        ...(drawRequestId
          ? []
          : [
              headers.drNumber({
                isLoading: false,
              }),
            ]),
        headers.inspectorAllowanceColumn({}),
        headers.serviceActions({
          source: 'project__services_tab__list',
          lastCompletedInspection,
        }),
        headers.serviceDocumentsAndComments({
          updateRightDrawer,
          serviceTypesMap,
          source: 'project__services_tab__list',
        }),
      ]),
    [
      drawRequestId,
      projectQuery.data,
      teamRole,
      serviceTypesMap,
      lastCompletedInspection,
      permissions,
    ],
  );

  return {
    state: drawRequestId
      ? getHookState([drawRequestInspectionsQuery, drawRequestServiceOrdersQuery])
      : getHookState([allInspectionsQuery, allServiceOrdersQuery]),
    rows: servicesList,
    columns,
    pdf,
    gallery,
    close,
    rightDrawerParams: { ...rightDrawerParams, projectId },
    rightMenu,
    isUpdating: drawRequestInspectionsQuery.isFetching || allInspectionsQuery.isFetching,
    inspectionCadence: projectQuery.data?.inspection_cadence,
    TABS,
    tab,
    handleTabChange,
    paginationProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onPageChange,
      onRowsPerPageChange,
      itemsCount: servicesList?.length,
    },
    setFilterStringQuery,
    noFiltersApplied: !filterStringQuery,
    handleRowClick,
    setTableSortingModel,
  };
};
