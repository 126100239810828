import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  FormControl,
} from '@mui/material';
import { useStringFieldModel } from '@models';
import { CustomSelect, CustomTextField } from '@components';
import { rolesNewMap } from '@constants';
import { IRoleNew } from '@interfaces';

interface AddRoleDialogProps {
  isOpen: boolean;
  onClose: () => void;
  updateHandler: (data: Partial<IRoleNew>) => void;
  item?: Partial<IRoleNew>;
}

export const AddRoleDialog: React.FC<AddRoleDialogProps> = ({
  isOpen,
  onClose,
  updateHandler,
  item,
}) => {
  const companyRole = useStringFieldModel({
    initValue: item?.company_role || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
    withProgressCheck: true,
  });

  const platformRole = useStringFieldModel({
    initValue: item?.platform_role || 'LENDER',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
    withProgressCheck: true,
  });

  const reviewLevel = useStringFieldModel({
    initValue: item?.review_level?.toString() || '0',
    validationRule: (value) => {
      const num = parseInt(value);
      return !isNaN(num) && num >= 0;
    },
    validateOnChange: true,
    withProgressCheck: true,
  });

  const handleSubmit = () => {
    if (companyRole.isValid && platformRole.isValid && reviewLevel.isValid) {
      updateHandler({
        company_role: companyRole.value,
        platform_role: platformRole.value,
        review_level: parseInt(reviewLevel.value),
      });
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Role</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <CustomTextField
            label="Company Role"
            field={companyRole}
            validationText="Company role is required"
            fullWidth
            required
          />
          <FormControl fullWidth required error={!platformRole.isValid}>
            <CustomSelect
              label="Platform Role"
              disabled={!!item?.platform_role}
              optionsList={Object.keys(rolesNewMap)}
              selectedOptions={[platformRole.value]}
              handleSelectChange={(e) => platformRole.setValue(e.target.value as string)}
              disableMultiple={true}
            />
          </FormControl>
          <CustomTextField
            label="Review Level"
            field={reviewLevel}
            validationText="Review level is required"
            fullWidth
            required
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!companyRole.isValid || !platformRole.isValid || !reviewLevel.isValid}
        >
          {item ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
