import React from 'react';
import { CellRenderWithError, MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter, isMilestoneIncomplete, notAllowNegative } from '@utils';
import { CurrencyInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid';
import { InputBaseComponentProps } from '@mui/material';

// TODO add permissions
const approvedAmount: MilestoneListColumnTypeV2 = {
  field: 'approved_amount',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved for this draw ($)',
  description: 'Amount approved to fund for this draw',
  valueParser: (value) => +value,
  valueFormatter: (value) => currencyFormatter(value, ''),
  renderCell: (params) => <CellRenderWithError params={params} />,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  minWidth: 120,
  editableByMilestone: (row) => row.isEditableV2 && isMilestoneIncomplete(row, 'approved_amount'),
};

export default approvedAmount;
