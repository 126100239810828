import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import { approximationEqual, isPercentAllowed, isRestricted, percentFormatter } from '@utils';
import { PermissionsContext, SettingsContext } from '@context';
import { TableContext } from '../controller';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { useDebounceInput, useStringFieldModel } from '@models';
import { CommonRowType } from '@interfaces';
import { TextInputWithTooltip } from '@components';
import { ColumnV2Width, PermissionNamesEnums, TableKeyEnum } from '@interfaces';

const InspectorAllowancePercentInput: FC<{ row: CommonRowType }> = ({ row }) => {
  const { tableKey, resetMutation, apiUpdate, getLineItemError } = useContext(TableContext);
  const { isPHBProject } = useContext(SettingsContext);
  const error = getLineItemError(row.id, 'inspector_allowance_rate');

  const inspector_allowance_rate = useStringFieldModel({
    initError: error,
    initValue: (row['inspector_allowance_rate'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'inspector_allowance_rate',
  });
  const { permissions } = useContext(PermissionsContext);
  const isEditable = useMemo(
    () =>
      tableKey === TableKeyEnum.INSPECTION_ENTER_RESULTS &&
      !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions) &&
      (isPHBProject ? row.activeToEdit : true),
    [permissions, row, tableKey, isPHBProject],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: inspector_allowance_rate.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(
          row.inspector_allowance_rate,
          inspector_allowance_rate.floatValue,
        ),
        name: 'inspector_allowance_rate',
        ...props,
      }),
    [inspector_allowance_rate.floatValue, row.inspector_allowance_rate],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: inspector_allowance_rate.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { inspector_allowance_rate: 0 } }),
    afterEffect: () => inspector_allowance_rate.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <TextInputWithTooltip
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
      onBlur={onBlur}
      value={inspector_allowance_rate.value}
      onChange={onChange}
      error={!inspector_allowance_rate.isValid}
      type="percent"
      tooltipText={row.disabled?.reason}
      disabled={row.disabled?.value}
      dataTestName="inspector_allowance_rate"
    />
  ) : (
    <Typography variant="body3" data-cy="inspector_allowance_rate">
      {percentFormatter({ value: row.inspector_allowance_rate })}
    </Typography>
  );
};

const inspectorAllowanceRate: MilestoneListColumnType = {
  name: 'inspector_allowance_rate',
  initDataField: 'inspector_allowance_rate',
  columnText: 'Inspection allowance (%)',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  isEditable: (permissions) => !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
  renderCell: ({ row }) => <InspectorAllowancePercentInput row={row} />,
  Footer: (row) => (
    <Typography variant="body3SemiBold" data-cy="total_inspector_allowance_rate">
      {percentFormatter({ value: row.inspector_allowance_rate })}
    </Typography>
  ),
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Onsite completion percentage">
      <ColumnLabel>Inspection</ColumnLabel>
      <ColumnLabel> allowance (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default inspectorAllowanceRate;
