import React, { FC, ReactElement, ReactNode, useContext, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Box, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  ColumnV2Width,
  IDocument,
  IMilestone,
  IMilestoneTotal,
  IProject,
  IProofpoint,
  ITransloaditSignature,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
} from '@interfaces';
import { colors } from '@theme';
import { currencyFormatter, openFullScreenPanorama, roundToTwoDigits } from '@utils';
import { Gallery, IconButtonWithTooltip, PanoramaViewer, PDFViewerNew } from '@components';
import { AddPhotoIcon, PhotoGalleryIconWithIndicator } from '@svgAsComponents';
import { useImagePicker } from '@hooks';
import { ViewerAPI } from 'react-photo-sphere-viewer';

import { TableContext } from '../controller';

export const ListWrapper = styled(Stack)({
  flex: 1,
  outline: 'none',
  ' *': {
    outline: 'none',
  },
  ' .Header': {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  ' .Body': {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  ' .Footer': {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 100000000,
  },
  ' .Footer-locked': {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    zIndex: 100000000,
  },
});

const StyledArrow = styled(Box)({
  display: 'inline-block',
  paddingRight: 5,
  color: colors.text.dark,
});

export const ProgressionSum: FC<{
  sum?: number;
  progress: number;
  isError?: boolean;
  dataTestName?: string;
}> = ({ sum, progress, isError, dataTestName }) => {
  const { withProgress } = useContext(TableContext);
  if (!progress || !withProgress) return null;

  const newBalance = roundToTwoDigits(sum) + roundToTwoDigits(progress);

  return (
    <Typography
      sx={{ position: 'relative' }}
      color={
        isError || newBalance < 0 ? colors.status.error.medium : colors.status.information.medium
      }
      variant="body3"
      data-cy={dataTestName}
    >
      {newBalance !== sum && <StyledArrow>➝</StyledArrow>}
      {currencyFormatter(newBalance)}
    </Typography>
  );
};

type CellProps = {
  row: Partial<IMilestone>;
  indx?: number;
};

type EditableFunction = (permissions: string[]) => boolean;

type TableActionColumns =
  | 'documents_photos_gallery_menu'
  | 'documents_photos_uploader_menu'
  | 'inspection_photos_uploader';

export type MilestoneListItem = {
  name: keyof IMilestone | keyof ProductionBuildCommonRowType | TableActionColumns;
  columnText: string;
  permissionKey?: PermissionNamesEnums;
  Header?:
    | ReactNode
    | string
    | ReactElement
    | (({ project }: { project?: IProject }) => ReactElement);
  isEditable?: boolean | EditableFunction;
  Footer?: (row: IMilestoneTotal) => ReactNode;
  renderCell?: (props: CellProps) => ReactNode;
  justify?: string;
  width?: string;
  minWidth: (flag?: boolean) => ColumnV2Width;
  maxWidth: (flag?: boolean) => ColumnV2Width;
  initDataField?: keyof IMilestone;
  noMargin?: boolean;
  justifyContent?: string;
  noBorder?: boolean;
  isSpacer?: boolean;
};

export type ApiUpdatePayload = {
  name: keyof IMilestone | keyof ProductionBuildCommonRowType | TableActionColumns;
  value?: number | string;
  milestoneId: string;
  needToCompare?: boolean;
  isEqualInit?: boolean;
  isNonNumber?: boolean;
  isModelBuilding?: boolean;
};

export type MilestoneListColumnType = MilestoneListItem;

export const ColumnLabelItem = ({ children, innerRef, ...props }) => (
  <Typography component="div" variant="columnLabel" {...props} ref={innerRef}>
    {children}
  </Typography>
);

export const ColumnLabel = React.forwardRef(function ColumnLabel(props: any, ref) {
  return <ColumnLabelItem {...props} innerRef={ref} />;
});

interface HeaderTextInterface extends StackProps {
  tooltipText?: string;
}

export const HeaderText: FC<HeaderTextInterface> = ({ children, tooltipText, ...props }) =>
  tooltipText ? (
    <Tooltip title={tooltipText}>
      <Stack
        flex={1}
        alignItems="flex-end"
        justifyContent="center"
        flexDirection="column"
        flexWrap="wrap"
        {...props}
      >
        {children}
      </Stack>
    </Tooltip>
  ) : (
    <Stack
      flex={1}
      alignItems="flex-end"
      justifyContent="center"
      flexDirection="column"
      flexWrap="wrap"
      {...props}
    >
      {children}
    </Stack>
  );

export const MilestoneImages: FC<{
  images: (IProofpoint | IDocument)[];
  canAddPhotos: boolean;
  disabled: {
    value: boolean;
    reason: string;
  };
  uploadClick: () => void;
  isFilesUploaderOpened: boolean;
  transloaditSignature: ITransloaditSignature;
  uploader: () => ReactElement;
  generalRowDataSource?: string;
}> = ({
  images,
  canAddPhotos,
  disabled,
  uploadClick,
  isFilesUploaderOpened,
  transloaditSignature,
  uploader,
  generalRowDataSource,
}) => {
  const { pdf, gallery, open, close, startIndex } = useImagePicker();
  const { source } = useContext(TableContext);
  const [showPanorama, setShowPanorama] = React.useState(false);
  const ref = useRef<ViewerAPI>();

  const canOpenPanorama = useMemo(
    () => images?.length === 1 && images[0]?.is_panorama,
    [images?.length, images?.[0]?.is_panorama],
  );

  return (
    <>
      {showPanorama && <PanoramaViewer ref={ref} />}
      <Stack direction="row" justifyContent="flex-end" flexWrap="nowrap" alignItems="center">
        {Boolean(images?.length) && (
          <IconButtonWithTooltip
            onClick={() => {
              if (canOpenPanorama) {
                openFullScreenPanorama(setShowPanorama, ref, images[0]);
              } else {
                open(images, 0);
              }
            }}
            data-cy={
              source
                ? `${source}__body__photos__gallery__icon`
                : `${generalRowDataSource}__photos__gallery__icon}`
            }
          >
            <PhotoGalleryIconWithIndicator hasUnseenImages={false} />
          </IconButtonWithTooltip>
        )}
        {canAddPhotos && (
          <IconButtonWithTooltip
            tooltipText={disabled?.reason}
            disabled={disabled?.value}
            onClick={uploadClick}
            data-cy={
              source
                ? `${source}__body__photos__upload__icon`
                : `${generalRowDataSource}__photos__upload__icon}`
            }
          >
            <AddPhotoIcon />
          </IconButtonWithTooltip>
        )}
      </Stack>
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
};

export const HeaderStick = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSticky',
  slot: 'Root',
})<{ isSticky: boolean }>(({ theme, isSticky }) => ({
  backgroundColor: theme.palette.background.default,
  position: isSticky ? 'fixed' : 'relative',
  display: isSticky ? 'flex' : 'none',
  flexWrap: 'nowrap',
  top: 0,
  zIndex: theme.zIndex.modal - 1,
  ['& > div']: {
    height: 48,
    display: 'flex',
    flexWrap: 'nowrap',
    transition: 'all 0.15s linear',
  },
}));

export const PseudoCell = styled('div')(() => ({
  overflow: 'hidden',
  padding: '16px',
  paddingLeft: 0,
  marginRight: '8.5px',
  display: 'flex',
  alignItems: 'center',
  width: '100px',
  borderRight: `0.5px solid ${colors.neutral.lighter}`,
}));

export const HeaderCell = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noBorder',
})<{
  noBorder?: boolean;
}>(({ noBorder }) => ({
  padding: '8.5px 16px',
  display: 'flex',
  alignItems: 'center',
  background: colors.neutral.lightest,
  borderLeft: `0.5px solid ${noBorder ? colors.neutral.lightest : colors.neutral.lighter}`,
  borderRight: `0.5px solid ${noBorder ? colors.neutral.lightest : colors.neutral.lighter}`,
}));

export const Cell = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'isHightlight' &&
    prop !== 'noBorder' &&
    prop !== 'noMargin' &&
    prop !== 'isActiveMilestone' &&
    prop !== 'isComplete' &&
    prop !== 'isActive',
  slot: 'Root',
})<{
  isActiveMilestone: boolean;
  isComplete: boolean;
  isActive: boolean;
  noBorder?: boolean;
  noMargin?: boolean;
  isHightlight?: boolean;
}>(({ theme, isActiveMilestone, isComplete, isActive, noBorder, noMargin, isHightlight }) => {
  const backgroundColor = isActiveMilestone
    ? colors.status.information.lightest
    : isComplete
      ? colors.neutral.lightest
      : colors.background.white;
  const boxShadow = isHightlight
    ? {
        boxShadow: `inset 0px 0px 5px ${colors.status.success.dark}`,
        transition: 'transform ease 0.5s, box-shadow ease 0.5s',
      }
    : {};

  const verticalBorderColor = isActive ? colors.status.success.dark : colors.neutral.lighter;
  const horizontalBorderColor = isActive
    ? colors.status.success.dark
    : noBorder
      ? backgroundColor
      : colors.neutral.lighter;

  return {
    backgroundColor,
    borderTop: `0.5px solid ${verticalBorderColor}`,
    borderBottom: `0.5px solid ${verticalBorderColor}`,
    borderLeft: `0.5px solid ${horizontalBorderColor}`,
    borderRight: `0.5px solid ${horizontalBorderColor}`,
    padding: noMargin ? 0 : theme.spacing(0, 2),
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    ...boxShadow,
  };
});
