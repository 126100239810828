import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SxProps, Theme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from 'react-query';

import { ICompanyFull, QueryNamesEnums } from '@interfaces';
import { getMyCompany } from '@globalService';
import { DateFieldModel } from '@models';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DatePickerWithTimezoneProps {
  field: DateFieldModel;
  label: string;
  validationText?: string;
  minDate?: Date;
  maxDate?: Date;
  sx?: SxProps<Theme>;
  inputStyles?: SxProps<Theme>;
  validateOnLoad?: boolean;
  disabled?: boolean;
  dataTestName?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  inputProps?: object;
  addTimestamp?: boolean;
}

const DatePickerWithTimezone = ({
  field,
  label,
  validationText = 'Invalid date',
  inputStyles,
  validateOnLoad = true,
  required,
  inputProps,
  onChange,
  dataTestName,
  ...props
}: DatePickerWithTimezoneProps) => {
  const [isTouched, setTouched] = React.useState(validateOnLoad);
  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const timezone = companyQuery?.data?.timezone || 'US/Pacific';

  const dateValue = field.value ? dayjs.utc(field.value) : null;

  const handleDateChange = (newDate: any) => {
    const jsDate = newDate ? newDate.toDate() : null;
    if (onChange) {
      onChange(jsDate);
    } else {
      field.handleChangePicker(jsDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={2}>
        <DatePicker
          label={`${label} (${timezone})`}
          timezone={timezone}
          value={dateValue}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: 'outlined',
              helperText:
                field.isValid || !isTouched
                  ? ''
                  : field.value
                    ? field.validationError || validationText
                    : `${label} is required`,
              error: !field.isValid && isTouched,
              size: 'small',
              sx: inputStyles,
              onBlur: () => setTouched(true),
              required,
              inputProps: {
                'data-cy': dataTestName,
                ...inputProps,
              },
            },
            openPickerIcon: {
              'data-cy': 'calendar-icon',
            },
          }}
          {...props}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePickerWithTimezone;
