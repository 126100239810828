import React from 'react';
import { Grid2, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import * as Controller from './controller';
import AnalyticsDashboard from '@assets/analytics_dashboard.svg';
import { HookState } from '@interfaces';
import { Card, LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import { colors } from '@theme';
import { AnalyticsImage } from '@svgAsComponents';

const AnalyticsCenter = () => {
  const controller = Controller.useSupersetDashboard();
  const { dashboardsList, state } = controller;
  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="analytics center" />
        </StyledBox>
      );
    }

    case HookState.EMPTY: {
      return (
        <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
          <AnalyticsImage />
          <Typography sx={{ mt: 8, textAlign: 'center' }} variant="h2">
            No analytics
          </Typography>
          <Typography sx={{ textAlign: 'center', color: colors.text.heading }} variant="body1">
            Please, ask your account manager for help.
          </Typography>
        </Stack>
      );
    }

    case HookState.SUCCESS: {
      return (
        <Stack sx={{ height: '100%' }}>
          <Typography variant="h1" sx={{ m: 2, mt: 3 }}>
            Analytics center
          </Typography>
          <Stack
            sx={{
              flexGrow: 1,
              backgroundColor: colors.neutral.lightest,
              py: 2,
              px: 1,
            }}
          >
            <Grid2 container spacing={2} py={2} px={3}>
              {dashboardsList.map((dashboard) => (
                <Grid2 key={dashboard.id} size={{ xs: 12, lg: 6 }}>
                  <Link
                    to={`/analytics/${dashboard.id}`}
                    style={{ textDecoration: 'none' }}
                    data-cy={`analytics_center__dashboard`}
                  >
                    <Card sx={{ width: '100%', height: '100%', minHeight: '162px' }}>
                      <Stack direction="row" justifyContent="flex-start" alignItems="center">
                        <Stack
                          flexGrow={1}
                          justifyContent="center"
                          alignItems="center"
                          sx={{ minWidth: '91px', maxWidth: '91px', marginRight: '28px' }}
                        >
                          <img
                            src={AnalyticsDashboard}
                            style={{ width: '100%' }}
                            alt="analytics dashboard"
                          />
                        </Stack>

                        <Stack
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          alignSelf="flex-start"
                        >
                          <Typography variant="h3">{dashboard.name}</Typography>
                          <Typography
                            variant="body1"
                            sx={{ mt: 1 }}
                            dangerouslySetInnerHTML={{ __html: dashboard.dashboard_description }}
                          />
                        </Stack>
                      </Stack>
                    </Card>
                  </Link>
                </Grid2>
              ))}
            </Grid2>
          </Stack>
        </Stack>
      );
    }

    default: {
      return null;
    }
  }
};

export default AnalyticsCenter;
