import { useContext, useMemo, useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { MilestoneTagsTypesEnums, QueryNamesEnums } from '@interfaces';
import { useProjectDetailsFields } from '@hooks';
import {
  findIdInArrByKey,
  getPropertyDataForPayload,
  isActiveProject,
  getMilestoneTagId,
} from '@utils';
import { SettingsContext } from '@context';

import { useProjectMutations } from './mutations';
import { useProjectQueries } from './queries';
import { useProjectRows } from './hooks/useProjectRows';
import { ControllerInterface, GeneralSettingsProps } from './interfaces';

export const useGeneralSettings = ({
  project,
  editable,
}: GeneralSettingsProps): ControllerInterface => {
  const { isPHBProject } = useContext(SettingsContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [isProductionBuildProject, setIsProductionBuildProject] = useState(isPHBProject);

  const {
    projectDetailsFields,
    exitStrategiesList,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
  } = useProjectDetailsFields({
    project,
    isProductionBuildProject,
  });

  const {
    projectName,
    scopeOfWork,
    exitStrategy,
    projectType,
    existingPropertyType,
    proposedPropertyType,
    address_1,
    city,
    state,
    zipCode,
    number_of_units,
    main_level_name,
    nested_level_name,
  } = projectDetailsFields;
  const { id: projectId } = project || {};

  useEffect(() => {
    if (isPHBProject !== isProductionBuildProject) {
      setIsProductionBuildProject(isPHBProject);
    }
  }, [isPHBProject]);

  const { projectModelsQuery, projectBuildingQuery, projectMilestoneTagsQuery } = useProjectQueries(
    projectId,
    isPHBProject,
  );

  const {
    projectMutation,
    patchBuildingMutation,
    addBuildingMutation,
    addUnitBuildingMutation,
    addBuildingModelsMutation,
    patchMsTag,
    postMsTag,
  } = useProjectMutations(projectId);

  useEffect(() => {
    const modelTag = projectMilestoneTagsQuery.data?.results.find(
      (tag) => tag.type === MilestoneTagsTypesEnums.MODEL,
    );
    const unitTag = projectMilestoneTagsQuery.data?.results.find(
      (tag) => tag.type === MilestoneTagsTypesEnums.UNIT,
    );
    const isBothLevels = modelTag && unitTag;
    const mainLevelName = isBothLevels ? modelTag : unitTag;
    const nestedLevelName = isBothLevels ? unitTag : null;

    if (mainLevelName) {
      main_level_name.setValue({
        name: mainLevelName.name,
        name_display: mainLevelName.name,
      });
    }
    if (nestedLevelName) {
      nested_level_name.setValue({
        name: nestedLevelName.name,
        name_display: nestedLevelName.name,
      });
    }
  }, [projectMilestoneTagsQuery.data]);

  const updateMsTag = async ({ type, name, projectId, tagId }) => {
    const tagData = {
      name,
      type,
    };

    if (tagId) {
      await patchMsTag.mutateAsync({
        projectId,
        milestoneTagId: tagId,
        json: tagData,
      });
    } else {
      await postMsTag.mutateAsync({
        projectId,
        json: tagData,
      });
    }
  };

  const updateMainAndNestedLevels = async () => {
    const isBothLevels = main_level_name.value && nested_level_name.value;
    const modelTagId = getMilestoneTagId(
      projectMilestoneTagsQuery.data?.results,
      MilestoneTagsTypesEnums.MODEL,
    );
    await updateMsTag({
      type: isBothLevels ? MilestoneTagsTypesEnums.MODEL : MilestoneTagsTypesEnums.UNIT,
      name: main_level_name.value?.name_display,
      projectId,
      tagId: modelTagId,
    });

    const unitTagId = getMilestoneTagId(
      projectMilestoneTagsQuery.data?.results,
      MilestoneTagsTypesEnums.UNIT,
    );
    if (isBothLevels) {
      await updateMsTag({
        type: MilestoneTagsTypesEnums.UNIT,
        name: nested_level_name.value?.name_display,
        projectId,
        tagId: unitTagId,
      });
    }
  };

  const updateProjectData = async () => {
    if (propertyDetails?.isEmptyDetailExist) {
      enqueueSnackbar('Property details should be filled', { variant: 'error' });
      return false;
    }

    if (!projectDetailsFields.projectName.validate()) return false;

    if (!isPHBProject && propertyDetails?.isChanged) {
      if (propertyDetails?.id) {
        await patchBuildingMutation.mutateAsync({
          project: projectId,
          building: propertyDetails.id.toString(),
          json: { details: getPropertyDataForPayload(propertyDetails?.list) },
        });
      } else {
        await addBuildingMutation.mutateAsync({
          projectId,
          details: getPropertyDataForPayload(propertyDetails?.list),
        });
      }
    }

    const projectPayload = {
      ...(scopeOfWork.isChanged && { scope_of_work: scopeOfWork.value }),
      ...(projectName.isChanged && { name: projectName.value }),
      ...(exitStrategy.isChanged && {
        exit_strategy: findIdInArrByKey({
          arr: exitStrategiesList,
          searchedVal: exitStrategy.value?.name_display,
        }),
      }),
      ...(projectType.isChanged && {
        type: findIdInArrByKey({
          arr: projectTypesList,
          searchedVal: projectType.value?.name_display,
        }),
      }),
      ...(existingPropertyType.isChanged && {
        property_existing_type: findIdInArrByKey({
          arr: propertyTypesList,
          searchedVal: existingPropertyType.value?.name_display,
        }),
      }),
      ...(proposedPropertyType.isChanged && {
        property_proposed_type: findIdInArrByKey({
          arr: propertyTypesList,
          searchedVal: proposedPropertyType.value?.name_display,
        }),
      }),
      ...(address_1.isChanged || city.isChanged || state.isChanged || zipCode.isChanged
        ? {
            address: {
              address_1: address_1.value,
              city: city.value,
              state: state.value?.name,
              zip_code: zipCode.value,
              id: project?.address?.id,
            },
          }
        : {}),
      ...(number_of_units.isChanged && {
        units_number: +number_of_units.value,
      }),
      is_advanced_budget_tracking_enabled: isProductionBuildProject,
    };
    await projectMutation.mutateAsync({
      projectId,
      json: projectPayload,
    });
    if (isProductionBuildProject) {
      await updateMainAndNestedLevels();
    }
    await invalidateQueries();
  };

  const updateUnits = async () => {
    if (+unitsNumber > 1 && !isCreateByModels) {
      const json = unitsRows.map((row) => ({
        project: projectId,
        details: getPropertyDataForPayload(row.propertyDetails),
        name: row.property_name,
        sqft: row.sqft,
        description: row.description,
      }));

      await addUnitBuildingMutation.mutateAsync({
        projectId,
        json,
      });
    } else if (isCreateByModels) {
      const json = modelsRows
        .filter((row) => !row.parentId)
        .map((row) => ({
          project: projectId,
          details: getPropertyDataForPayload(row.propertyDetails),
          name: row.property_name,
          sqft: row.sqft,
          description: row.description,
          quantity: row.quantity,
        }));

      await addBuildingModelsMutation.mutateAsync({
        projectId,
        json,
      });
    }
  };

  const handleSubmitClick = async () => {
    await updateProjectData();
    await updateUnits();
    return true;
  };

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId: project.id }]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES,
      { projectId: project.id },
    ]);
    await queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS);
  };

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  const isCreateByModels = useMemo(() => {
    return !!projectModelsQuery.data?.count || !!nested_level_name.value?.name_display;
  }, [projectModelsQuery.data?.count, nested_level_name.value?.name_display]);

  const unitsNumber = useMemo(() => {
    return Number(number_of_units.value);
  }, [number_of_units.value]);

  const parentLevelName = useMemo(() => {
    return main_level_name.value?.name_display;
  }, [main_level_name.value]);

  const nestedLevelName = useMemo(() => {
    return nested_level_name.value?.name_display;
  }, [nested_level_name.value]);

  const { unitsRows, setUnitsRows, modelsRows, setModelsRows } = useProjectRows({
    unitsNumber,
    isCreateByModels,
    parentLevelName,
    nestedLevelName,
    editable,
    projectBuildingData: projectBuildingQuery.data,
  });

  const updateIsProductionBuildProject = (value: boolean) => {
    setIsProductionBuildProject(value);
    if (!value) return;
    if (!main_level_name.value) {
      main_level_name.setValid(false);
    }
    if (!nested_level_name.value) {
      nested_level_name.setValid(false);
    }
  };

  return {
    projectDetailsFields,
    exitStrategiesList,
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    exitPath,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
    isCreateByModels,
    number_of_units,
    main_level_name,
    nested_level_name,
    isProductionBuildProject,
    updateIsProductionBuildProject,
    propertyRows: isCreateByModels ? modelsRows : unitsRows,
    setPropertyRows: isCreateByModels ? setModelsRows : setUnitsRows,
    isActiveProject: isActiveProject(project?.status),
  };
};
