import * as React from 'react';
import {
  useTreeItem2,
  TreeItem2Provider,
  TreeItem2Root,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Icon,
  TreeItem2Checkbox,
  TreeItem2DragAndDropOverlay,
  TreeItem2GroupTransition,
  TreeItem2Props,
} from '@mui/x-tree-view-pro';
import { Stack, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IconButtonWithTooltip, RoleIconWithLink } from '@components';
import { DeleteIcon, EditIcon } from '@svgAsComponents';
import { IRoleNew } from '@interfaces';

export const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: TreeItem2Props & {
    selectItemForEdit?: (role: Partial<IRoleNew>) => void;
    selectItemForDelete?: (role: Partial<IRoleNew>) => void;
  },
  ref: React.Ref<HTMLLIElement>,
) {
  const {
    selectItemForEdit,
    selectItemForDelete,
    id,
    itemId,
    label,
    disabled,
    children,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  const item = publicAPI.getItem(itemId);
  const { draggable, onDragStart, onDragOver, onDragEnd, ...otherRootProps } = getRootProps(other);

  const handleDragStart = (event: React.DragEvent) => {
    if (!onDragStart) {
      return;
    }
    onDragStart(event);
    event.dataTransfer.setDragImage((event.target as HTMLElement).parentElement!, 0, 0);
  };

  const showDrawIcon = !item.children;
  const canBeDeleted = item.teams_count <= 0;

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...otherRootProps}>
        <TreeItem2Content
          {...getContentProps()}
          onDoubleClick={() => (showDrawIcon ? selectItemForEdit?.(item) : null)}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <TreeItem2IconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
            {showDrawIcon && (
              <>
                <TreeItem2IconContainer
                  draggable={draggable}
                  onDragStart={handleDragStart}
                  onDragOver={onDragOver}
                  onDragEnd={onDragEnd}
                  style={{ cursor: draggable ? 'move' : 'pointer' }}
                >
                  <DragIndicatorIcon />
                </TreeItem2IconContainer>
                <IconButtonWithTooltip
                  tooltipText="Edit item"
                  onClick={() => selectItemForEdit?.(item)}
                >
                  <EditIcon />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  tooltipText={!canBeDeleted ? 'Cannot be deleted' : ''}
                  disabled={!canBeDeleted}
                  onClick={() => selectItemForDelete?.(item)}
                >
                  <DeleteIcon />
                </IconButtonWithTooltip>
                <RoleIconWithLink roleId={item.id} />
              </>
            )}
            <TreeItem2Checkbox {...getCheckboxProps()} />
            <Stack pl={1} direction="row" spacing={0.5} alignItems="center">
              <Typography variant={showDrawIcon ? 'body3SemiBold' : 'body2SemiBold'}>
                {item.label}
              </Typography>
              {showDrawIcon && <Typography variant="label">{`[${item.platform_role}]`}</Typography>}
            </Stack>
            <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
          </Stack>
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});
