import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ITeam,
  IUser,
  IWatcher,
  PermissionNamesEnums,
  PostProjectWatchersParam,
  QueryNamesEnums,
} from '@interfaces';
import {
  getProjectCompanies,
  getProjectWatchers,
  postProjectWatchers,
  removeProjectWatchers,
} from '@globalService';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar, useUpdateUiSettings } from '@hooks';
import React, { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext, SettingsContext, PermissionsContext } from '@context';
import { filterActiveUsers, getUserFullNameOrEmail, isRestricted } from '@utils';
import { FilterObject, teamsFilter } from '@components';
import difference from 'lodash/difference';
import find from 'lodash/find';
import { rolesMap, TEAM_ROLES } from '@constants';

export interface ControllerInterface {
  watchers: IWatcher[];
  isCurrentUserWatching: boolean;
  getUserName: (companyUser: IWatcher | IUser) => string;
  handleMouseEnter: (id: string) => void;
  handleMouseLeave: () => void;
  hoveredWatcherId: string;
  filterConfig: FilterObject;
  setFilteredTeamIds: (teamIds: string[]) => void;
  filteredTeamIds: string[];
  selectedUsersIds: string[];
  allWatchersCount: number;
  isLoading: boolean;
  handleWatchersChange: (newWatchersId: string[]) => void;
  usersOptionsList: { label: string; value: string }[];
  handleAddWatcherClick: () => void;
  handleUsersListClose: () => void;
  ref: React.RefObject<HTMLDivElement>;
  anchorEl: null | HTMLElement;
  handleStartWatching: (id: string) => void;
  handleRemoveWatcher: (id: string) => void;
  isEditable: boolean;
  showNotificationsPopup: boolean;
  showStartWatchingButton: boolean;
  setShowNotificationsPopup: Dispatch<React.SetStateAction<boolean>>;
}

const sortByTeam = (arr: IWatcher[]) => {
  return arr.sort((a, b) => {
    if (a.teams[0] < b.teams[0]) return -1;
    if (a.teams[0] > b.teams[0]) return 1;
    return 0;
  });
};
const sortByName = (arr: IUser[]) => {
  return arr.sort((a, b) => {
    const nameA = (a.first_name || a.email).toLowerCase();
    const nameB = (b.first_name || b.email).toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

const getStringQueryParams = (teamIds?: string[]) =>
  `&team=${teamIds || ''}&team_role=${TEAM_ROLES.Lender}`;

export const useWatchProject = (): ControllerInterface => {
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const { isCurrentProjectArchived, settings } = useContext(SettingsContext);
  const [hoveredWatcherId, setHoveredWatcherId] = useState(null);
  const { updateSettings } = useUpdateUiSettings();
  const [filteredTeamIds, setFilteredTeamIds] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ref = React.useRef(null);
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[] | null>(null);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState<boolean>(false);

  const allWatchersParams = getStringQueryParams();
  const allProjectWatchersQuery = useQuery<{ results: IWatcher[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_WATCHERS, { projectId, stringQueryParams: allWatchersParams }],
    getProjectWatchers.bind(this, {
      projectId,
      stringQueryParams: allWatchersParams,
    }),
    { enabled: Boolean(projectId) },
  );
  const allWatchersList = allProjectWatchersQuery.data?.results;

  const filteredWatchersParams = getStringQueryParams(filteredTeamIds);
  const filteredProjectWatchersQuery = useQuery<{ results: IWatcher[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_WATCHERS,
      { projectId, stringQueryParams: filteredWatchersParams },
    ],
    getProjectWatchers.bind(this, {
      projectId,
      stringQueryParams: filteredWatchersParams,
    }),
    { enabled: Boolean(projectId) },
  );

  const projectCompaniesData = useQuery<ITeam[], Error>(
    [QueryNamesEnums.GET_PROJECT_COMPANIES, { projectId }],
    getProjectCompanies.bind(this, projectId),
  );

  const lenderCompany = useMemo(() => {
    if (projectCompaniesData.data?.length)
      return find(projectCompaniesData.data, { role: rolesMap.LENDER });
    return {};
  }, [projectCompaniesData.data]);

  const addProjectWatchersMutation = useMutation<Response, Error, PostProjectWatchersParam>(
    postProjectWatchers,
    {
      onSuccess: () => {
        setFilteredTeamIds([]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_WATCHERS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECTS_LIST]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const removeProjectWatchersMutation = useMutation<Response, Error, PostProjectWatchersParam>(
    removeProjectWatchers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_WATCHERS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECTS_LIST]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  useEffect(() => {
    if (!selectedUsersIds && allWatchersList?.length) {
      setSelectedUsersIds(allWatchersList.map((watcher) => watcher.id));
    }
  }, [allWatchersList, selectedUsersIds]);

  const isCurrentUserWatching = useMemo(
    () => selectedUsersIds?.includes(user.id),
    [user.id, selectedUsersIds],
  );

  const addWatcher = useCallback(
    async (userId: string) => {
      setSelectedUsersIds([...(selectedUsersIds || []), userId]);
      await addProjectWatchersMutation.mutateAsync({
        projectId,
        projectIds: [projectId],
        userIds: [userId],
      });
    },
    [projectId, addProjectWatchersMutation],
  );

  const removeWatcher = useCallback(
    async (userId: string) => {
      setSelectedUsersIds(selectedUsersIds.filter((id) => userId !== id));
      setFilteredTeamIds((old) => old.filter((id) => id !== userId));
      await removeProjectWatchersMutation.mutateAsync({
        projectId,
        projectIds: [projectId],
        userIds: [userId],
      });
    },
    [projectId, removeProjectWatchersMutation],
  );

  const getUserName = useCallback(
    (companyUser: IWatcher | IUser) =>
      `${getUserFullNameOrEmail(companyUser)} ${user.id === companyUser.id ? ' (You) ' : ''}`,
    [],
  );

  const activeUsersList = useMemo(
    () => filterActiveUsers(lenderCompany?.company_members),
    [lenderCompany?.company_members],
  );

  const handleMouseEnter = (id: string) => {
    setHoveredWatcherId(id);
  };

  const handleMouseLeave = () => {
    setHoveredWatcherId(null);
  };

  const watchers = useMemo(
    () => sortByTeam(filteredProjectWatchersQuery.data?.results || []),
    [filteredProjectWatchersQuery.data?.results],
  );

  const syncUserArrays = (
    oldWatchersIds: string[],
    newWatchersIds: string[],
    addUser: (id: string) => void,
    removeUser: (id: string) => void,
  ): void => {
    const idsToAdd = difference(newWatchersIds, oldWatchersIds);
    const idsToRemove = difference(oldWatchersIds, newWatchersIds);

    idsToAdd.forEach(addUser);
    idsToRemove.forEach(removeUser);
  };

  const handleWatchersChange = useCallback(
    (newWatchersId: string[]) => {
      syncUserArrays(selectedUsersIds, newWatchersId, addWatcher, removeWatcher);
    },
    [selectedUsersIds, addWatcher, removeWatcher],
  );

  const usersOptionsList = useMemo(
    () =>
      sortByName(activeUsersList)?.map((user) => ({
        label: getUserName(user),
        value: user.id,
      })),
    [activeUsersList],
  );

  const handleAddWatcherClick = () => {
    setAnchorEl(ref.current);
  };

  const handleUsersListClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleStartWatching = useCallback(
    async (id: string) => {
      await addWatcher(id);
      showNotificationSetupPopup();
    },
    [selectedUsersIds, addWatcher],
  );

  const handleRemoveWatcher = useCallback(
    async (id: string) => {
      await removeWatcher(id);
      if (user.id === id) {
        showNotificationSetupPopup();
      }
    },
    [selectedUsersIds, removeWatcher],
  );

  const showNotificationSetupPopup = () => {
    const isNotificationPopupWasShown = settings.personal_setting?.shownPopups?.setup_notifications;
    if (!isNotificationPopupWasShown) {
      setShowNotificationsPopup(true);
      updateSettings({
        personal_setting: {
          shownPopups: {
            ...settings.personal_setting?.shownPopups,
            setup_notifications: true,
          },
        },
      });
    }
  };

  const showStartWatchingButton = useMemo(() => {
    return (
      !isCurrentUserWatching &&
      !isCurrentProjectArchived &&
      isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
    );
  }, [isCurrentUserWatching, !isCurrentProjectArchived, permissions]);

  return {
    watchers,
    isCurrentUserWatching,
    getUserName,
    handleMouseEnter,
    handleMouseLeave,
    hoveredWatcherId,
    filterConfig: {
      ...teamsFilter,
      getDataParamsPaginated: (pagination, q, skip) => ({
        ...teamsFilter.getDataParamsPaginated(pagination, q, skip),
        args: { projectId, query: `role=${TEAM_ROLES.Lender}` },
      }),
    },
    setFilteredTeamIds,
    filteredTeamIds,
    allWatchersCount: allWatchersList?.length,
    isLoading: filteredProjectWatchersQuery.isFetching || allProjectWatchersQuery.isFetching,
    handleWatchersChange,
    usersOptionsList,
    selectedUsersIds,
    handleAddWatcherClick,
    handleUsersListClose,
    ref,
    anchorEl,
    handleStartWatching,
    handleRemoveWatcher,
    isEditable: !isCurrentProjectArchived,
    showNotificationsPopup,
    setShowNotificationsPopup,
    showStartWatchingButton,
  };
};
