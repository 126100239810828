import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { isPercentAllowed, isRestricted, percentFormatter } from '@utils';

import { ColumnV2Width, CommonRowType, PermissionNamesEnums } from '@interfaces';
import { PermissionsContext } from '@context';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { useParams } from 'react-router-dom';
import { TextInputWithTooltip } from '@components';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { requestId } = useParams();
  const { apiUpdate, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'retainage_rate');

  const retainage_rate = useStringFieldModel({
    initError: error,
    initValue: (row['retainage_rate'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'retainage_rate',
  });
  const { permissions } = useContext(PermissionsContext);

  const isEditable = useMemo(
    () =>
      !requestId &&
      row.activeToEdit &&
      row.localNew &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row, requestId],
  );

  const effect = useCallback(
    (props) => {
      +retainage_rate.floatValue !== +row['retainage_rate'] &&
        apiUpdate({
          milestoneId: row.id,
          value: retainage_rate.floatValue,
          name: 'retainage_rate',
          ...props,
        });
    },
    [retainage_rate.floatValue, row['retainage_rate']],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: retainage_rate.handleChange,
    afterEffect: () => retainage_rate.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <TextInputWithTooltip
      sx={{ width: '100%', maxWidth: 400 }}
      value={retainage_rate.value}
      onChange={onChange}
      onBlur={onBlur}
      type="percent"
      error={Boolean(error)}
      errorTip={error}
      dataTestName={`milestone_row_retainage_rate_index_${row.index}`}
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
    />
  ) : (
    <Typography variant="body3" data-cy={`milestone_row_retainage_rate_index_${row.index}`}>
      {percentFormatter({ value: row.retainage_rate })}
    </Typography>
  );
};

const retainageRateBudget: MilestoneListColumnType = {
  name: 'retainage_rate',
  columnText: 'Retainage (%)',
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: () => null,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Retainage (%)">
      <ColumnLabel>Retainage (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default retainageRateBudget;
