import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import {
  getActiveDrawRequestFromList,
  getDrawIcon,
  getFromListById,
  getHookState,
  isChangeRequest,
  isDrawRequest,
  isRequestDraft,
  isRequestHistorical,
  isRestricted,
} from '@utils';
import { PermissionsContext } from '@context';
import {
  DocumentTabEnums,
  PermissionNamesEnums,
  PoliciesTypeEnum,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectDrawRequestsList } from '@globalService';
import { useCommentsAndDocumentsPreview } from '@hooks';
import { ControllerInterface } from './interface';
import { PoliciesTypeLabel } from '@constants';
import snakeCase from 'lodash/snakeCase';
import { getDataTestNameLabel, getDrawLabel, getFirstTabText, TABS } from './utils';

export const useProject = (projectId): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({ projectId });

  const TABS_LIST = useMemo(() => {
    const tabs = [
      {
        ...TABS.OVERVIEW,
        dataTestName: `project_tab_${TABS.OVERVIEW.value}`,
        hasActionIcon: false,
      },
      {
        ...TABS.BUDGET,
        dataTestName: `project_tab_${TABS.BUDGET.value}`,
        hasActionIcon: false,
      },
      { ...TABS.DRAWS, dataTestName: `project_tab_${TABS.DRAWS.value}`, hasActionIcon: true },
      {
        ...TABS.DOCUMENTS,
        dataTestName: `project_tab_${TABS.DOCUMENTS.value}`,
        hasActionIcon: false,
      },
      ...(!isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
        ? [
            {
              ...TABS.SERVICES,
              dataTestName: `project_tab_${TABS.SERVICES.value}`,
              hasActionIcon: true,
            },
          ]
        : []),
      { ...TABS.PHOTOS, dataTestName: `project_tab_${TABS.PHOTOS.value}`, hasActionIcon: false },
      { ...TABS.PAYMENTS, dataTestName: `project_tab_${TABS.PAYMENTS.value}`, hasActionIcon: true },
      ...(!isRestricted(PermissionNamesEnums.POLICIES_MY_TEAM_ROLE_VIEW, permissions) ||
      !isRestricted(PermissionNamesEnums.POLICIES_VIEW, permissions)
        ? [
            {
              ...TABS.POLICIES,
              dataTestName: `project_tab_${TABS.POLICIES.value}`,
              hasActionIcon: false,
            },
          ]
        : []),
    ];
    return tabs;
  }, [permissions]);

  const drawRequestsQuery = useQuery(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    () => getProjectDrawRequestsList(projectId),
  );

  const drawRequestInProgress = useMemo(
    () =>
      drawRequestsQuery.data?.results?.length === 1
        ? drawRequestsQuery.data?.results[0]
        : getActiveDrawRequestFromList(drawRequestsQuery.data),
    [drawRequestsQuery.data],
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const isInspectionsTab = matchPath('/projects/:projectId/inspections/*', pathname);
  const isPaymentTab = matchPath('/projects/:projectId/payments/*', pathname);
  const isPoliciesTab = matchPath('/projects/:projectId/policies/*', pathname);
  const isDocumentsTab = matchPath('/projects/:projectId/documents/*', pathname);
  const isPhotosTab = matchPath('/projects/:projectId/photos/*', pathname);
  const isDrawsTab = matchPath('/projects/:projectId/draws/*', pathname);
  const [activeDrawRequestId, setActiveDrawRequestId] = useState(null);
  const [activeDrTab, setActiveDrTab] = useState(null);
  const activeDrawRequestIdMatch = match?.params['*']?.split('/')[1];

  useEffect(() => {
    const lastSubmittedRequest = isRequestDraft(drawRequestsQuery.data?.results?.[0]?.status)
      ? drawRequestsQuery.data?.results?.[1]?.id
      : drawRequestsQuery.data?.results?.[0]?.id || null;
    const id = activeDrawRequestIdMatch || drawRequestInProgress?.id || lastSubmittedRequest;
    setActiveDrawRequestId(id);
    setActiveDrTab(activeDrawRequestIdMatch || match?.params['*']?.split('/')[0] || 'all');
  }, [drawRequestsQuery.data, match, activeDrawRequestIdMatch]);

  const tabLinkPath = useCallback(
    (tab) => {
      const pathBase = `/projects/${projectId}`;
      if (tab === TABS.OVERVIEW.value || tab === TABS.BUDGET.value) {
        return `${pathBase}/${tab}`;
      }
      const tabPathname = match?.params['*']?.split('/')[0];
      const activeDrIsChange = isChangeRequest(
        getFromListById(drawRequestsQuery.data?.results, activeDrawRequestId),
      );

      if (
        activeDrIsChange &&
        [TABS.SERVICES.value, TABS.INSPECTIONS.value, TABS.PAYMENTS.value].includes(tab)
      ) {
        return `${pathBase}/${tab}/all`;
      }

      if (activeDrawRequestIdMatch) {
        return `${pathBase}/${tab}/draw-requests/${activeDrawRequestIdMatch}`;
      }

      if (tabPathname === DocumentTabEnums.ALL && tab !== TABS.DRAWS.value) {
        return `${pathBase}/${tab}/all`;
      }

      if (!drawRequestInProgress?.id && tab === TABS.POLICIES.value) {
        return `${pathBase}/${tab}/${PoliciesTypeEnum.FUTURE_DRAWS}`;
      }

      return !activeDrawRequestId
        ? `${pathBase}/${tab}/all`
        : `${pathBase}/${tab}/draw-requests/${activeDrawRequestId}`;
    },
    [
      activeDrawRequestId,
      activeDrawRequestIdMatch,
      drawRequestsQuery.data,
      drawRequestInProgress?.id,
      match,
      projectId,
    ],
  );

  const handleActiveDRTabChange = (value) => {
    const isDrTab = ![
      DocumentTabEnums.ALL,
      DocumentTabEnums.PROJECT,
      PoliciesTypeEnum.PROJECT,
      PoliciesTypeEnum.FIRST_DRAW,
      PoliciesTypeEnum.FUTURE_CHANGES,
      PoliciesTypeEnum.FUTURE_DRAWS,
    ].includes(value);
    const path = isDrTab
      ? `/projects/${match.params.projectId}/${match.params.tab}/draw-requests/${value}`
      : `/projects/${match.params.projectId}/${match.params.tab}/${value}`;
    navigate(path);
  };

  const showPoliciesTab = useMemo(
    () =>
      !isRestricted(PermissionNamesEnums.POLICIES_MY_TEAM_ROLE_VIEW, permissions) ||
      !isRestricted(PermissionNamesEnums.POLICIES_VIEW, permissions),
    [permissions],
  );

  const DRSwitcherTabs = useMemo(() => {
    const DRList = drawRequestsQuery.data?.results || [];
    const tabPathname = match?.params['*']?.split('/')[0];
    const firstTabLabel = isDrawsTab ? null : getFirstTabText(match?.params?.tab);
    const showFirstDrawPoliciesTab = !DRList.some((draw) => isDrawRequest(draw));

    return [
      ...(isPoliciesTab
        ? [
            {
              label: PoliciesTypeLabel.CHANGE_REQUEST,
              value: PoliciesTypeEnum.FUTURE_CHANGES,
              isActive: tabPathname === PoliciesTypeEnum.FUTURE_CHANGES,
              dataTestName: snakeCase(PoliciesTypeLabel.CHANGE_REQUEST),
            },
            {
              label: PoliciesTypeLabel.DRAW_REQUEST,
              value: PoliciesTypeEnum.FUTURE_DRAWS,
              isActive: tabPathname === PoliciesTypeEnum.FUTURE_DRAWS,
              dataTestName: snakeCase(PoliciesTypeLabel.DRAW_REQUEST),
            },
          ]
        : []),
      ...(isPoliciesTab && showFirstDrawPoliciesTab
        ? [
            {
              label: PoliciesTypeLabel.FIRST_DRAW_REQUEST,
              value: PoliciesTypeEnum.FIRST_DRAW,
              isActive: tabPathname === PoliciesTypeEnum.FIRST_DRAW,
              dataTestName: snakeCase(PoliciesTypeLabel.FIRST_DRAW_REQUEST),
            },
          ]
        : []),
      ...(firstTabLabel
        ? [
            {
              label: firstTabLabel,
              value: 'all',
              isActive: activeDrTab === 'all',
              dataTestName: `project__tab_switcher__${firstTabLabel.toLowerCase()}`,
            },
          ]
        : []),
      ...(isDocumentsTab || isPhotosTab
        ? [{ label: 'Project', value: DocumentTabEnums.PROJECT, isActive: true }]
        : []),
      ...DRList.filter((draw) =>
        isChangeRequest(draw) ? !isInspectionsTab && !isPaymentTab : true,
      ).map((draw) => ({
        label: getDrawLabel(draw, DRList),
        value: draw.id,
        isActive: activeDrTab !== 'all' && draw.id == activeDrawRequestId,
        dataTestName: getDataTestNameLabel(draw),
        icon: getDrawIcon(draw),
      })),
      ...(isPoliciesTab &&
      (!isRestricted(PermissionNamesEnums.POLICIES_VIEW, permissions) || showPoliciesTab)
        ? [
            {
              label: PoliciesTypeLabel.PROJECT,
              value: PoliciesTypeEnum.PROJECT,
              isActive: !activeDrawRequestId,
              dataTestName: snakeCase(PoliciesTypeLabel.PROJECT),
            },
          ]
        : []),
    ];
  }, [
    drawRequestsQuery.data,
    activeDrawRequestId,
    pathname,
    permissions,
    isDocumentsTab,
    isPhotosTab,
    isInspectionsTab,
    isPaymentTab,
    isPoliciesTab,
    activeDrTab,
  ]);

  const isActiveRequestHistorical = useMemo(
    () =>
      activeDrawRequestIdMatch === activeDrawRequestId &&
      isRequestHistorical(
        getFromListById(drawRequestsQuery.data?.results, activeDrawRequestId)?.source,
      ),
    [drawRequestsQuery.data?.results, activeDrawRequestId, activeDrawRequestIdMatch],
  );

  return {
    TABS,
    TABS_LIST,
    state: getHookState(drawRequestsQuery),
    activeDrawRequestId,
    activeDrTab,
    handleActiveDRTabChange,
    DRSwitcherTabs,
    updateCommentsPreviewInfo,
    tabLinkPath,
    isActiveRequestHistorical,
  };
};
