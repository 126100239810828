import { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import find from 'lodash/find';
import { useLocation } from 'react-router-dom';

import {
  DateFieldModel,
  DropdownFieldModel,
  PhoneFieldModel,
  StringFieldModel,
  useDateFieldModel,
  useDropdownFieldModel,
  usePhoneFieldModel,
  useStringFieldModel,
} from '@models';
import {
  DateValidationEnum,
  EnumTypeForList,
  IChecklistMetadata,
  IProject,
  ITeam,
  QueryNamesEnums,
} from '@interfaces';
import { getDateValidationRule, createDateAsLocal } from '@utils';
import { TEAM_ROLES } from '@constants';
import { getProjectTeamsWithQuery } from '@globalService';

interface HookInterface {
  additionalContactName: StringFieldModel;
  primaryContactPhone: PhoneFieldModel;
  additionalContactPhone: PhoneFieldModel;
  accessCode: StringFieldModel;
  primaryContactUserNotRequired: DropdownFieldModel;
  primaryContactUserList: EnumTypeForList[];
  inspectionCadence: StringFieldModel;
  isBorrowerUsersLoading: boolean;
  inspectionRequestedAt: DateFieldModel;
  isProjectInspectionSettingsUpdated: boolean;
  isContactListHasUsersWithPhone: boolean;
}

export const useInspectionFields = ({
  project,
  metadata,
}: {
  project: Partial<IProject>;
  metadata?: IChecklistMetadata;
}): HookInterface => {
  const location = useLocation();
  const isSettingsPage = location?.pathname.includes('/settings/');
  const {
    inspection_additional_contact_name,
    inspection_additional_contact_phone,
    inspection_entry_access_code,
    inspection_primary_contact_user_id,
    id: projectId,
    inspection_cadence,
  } = project || {};

  const inspectionCadence = useStringFieldModel({
    initValue: inspection_cadence?.toString() || '',
  });

  const query = `role=${TEAM_ROLES.Owner}`;
  const { data: { results: teamsList = [] } = {}, isLoading } = useQuery<
    { results: ITeam[] },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, query }],
    getProjectTeamsWithQuery.bind(this, { projectId, query }),
    { enabled: Boolean(projectId) },
  );

  const inspectionRequestedAt = useDateFieldModel({
    initValue: new Date(),
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.MORE_OR_EQUAL,
        minDate: new Date(),
        required: false,
      }),
    validateOnChange: true,
  });

  const primaryContactUserNotRequired = useDropdownFieldModel({
    initValue: null,
  });

  const additionalContactName = useStringFieldModel({
    initValue: inspection_additional_contact_name || '',
    validationRule: (value) =>
      !primaryContactUserNotRequired?.value?.id ? Boolean(value?.trim()) : true,
    validateOnChange: true,
  });

  const primaryContactPhone = usePhoneFieldModel({
    initValue: '',
  });
  const additionalContactPhone = usePhoneFieldModel({
    initValue: inspection_additional_contact_phone || '+1',
    isRequired: !primaryContactUserNotRequired?.value,
  });

  const accessCode = useStringFieldModel({
    initValue: inspection_entry_access_code || '',
    validationRule: (value) => (value?.length || 0) <= 50,
    validateOnChange: true,
  });

  useEffect(() => {
    additionalContactName.validate();
    additionalContactPhone.validate();
  }, [primaryContactUserNotRequired.value]);

  const primaryContactUserList = useMemo(
    () =>
      teamsList?.[0]?.members?.map((o) => ({
        id: o.id,
        name_display: o?.full_name || o?.email,
        name: o?.full_name || o?.email,
        phone: o?.phone,
        disabled: !o?.phone,
      })) || [],
    [teamsList],
  );

  const [isInitMetadataUsed, setIsInitMetadataUsed] = useState(false);
  useEffect(() => {
    if (!isEmpty(metadata) && !isInitMetadataUsed) {
      additionalContactName.setValue(metadata?.inspection_additional_contact_name);
      additionalContactPhone.setValue(metadata?.inspection_additional_contact_phone || '+1');
      accessCode.setValue(metadata?.inspection_entry_access_code);
      inspectionRequestedAt.setValue(
        metadata?.date ? createDateAsLocal(metadata.date) : new Date(),
      );

      setIsInitMetadataUsed(true);
    }

    if (primaryContactUserList?.length) {
      // not to set the value if it's a settings page
      if (isSettingsPage && !inspection_primary_contact_user_id) return;
      const primaryContact =
        // if there is no metadata we always send the inspection_primary_contact_user_id from the project level, this field is required
        find(primaryContactUserList, {
          id: metadata?.inspection_primary_contact_user_id || inspection_primary_contact_user_id,
        }) || primaryContactUserList.find((option) => !option.disabled);
      if (!primaryContact) return;
      if (primaryContact.id === inspection_primary_contact_user_id) {
        primaryContactUserNotRequired.setInitValue(primaryContact);
      } else {
        primaryContactUserNotRequired.setValue(primaryContact);
      }
    }
  }, [primaryContactUserList, inspection_primary_contact_user_id, metadata, isInitMetadataUsed]);

  useEffect(() => {
    primaryContactPhone.setValue(primaryContactUserNotRequired?.value?.phone || '+1');
  }, [primaryContactUserNotRequired?.value?.id]);

  const isContactListHasUsersWithPhone = useMemo(() => {
    return primaryContactUserList.some((user) => user.phone);
  }, [primaryContactUserList]);

  const isProjectInspectionSettingsUpdated = useMemo(
    () =>
      [
        additionalContactName,
        additionalContactPhone,
        accessCode,
        primaryContactUserNotRequired,
        inspectionCadence,
      ].some((field) => field.isChanged),
    [
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserNotRequired.value?.id,
      project,
    ],
  );

  return {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    primaryContactPhone,
    primaryContactUserList,
    inspectionCadence,
    isBorrowerUsersLoading: isLoading,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
    isContactListHasUsersWithPhone,
  };
};
