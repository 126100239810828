import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { GridColDef } from '@mui/x-data-grid';
import { Stack, Typography } from '@mui/material';
import { checkIsInvestor, checkIsLender, getTeamRole, isCreatedByUser, isRestricted } from '@utils';
import {
  ChangeProjectDocumentParam,
  IDocument,
  IProjectDocument,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { AuthContext, PermissionsContext } from '@context';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useRightMenu,
  useSafeSnackbar,
  useUrlParams,
} from '@hooks';
import { patchProjectDocument } from '@globalService';
import { ControllerInterface, IDocumentsWrapperProps } from '../../interface';
import {
  actionsColumn,
  companyPrivacyColumn,
  dateUploadedColumn,
  documentTypeColumn,
  drNumberColumn,
  lineItemV2Column,
  nameColumn,
  processedStatusColumn,
  uploadedByColumn,
} from './columns';
import { MagicWandIcon } from '@svgAsComponents';
import { BetaChip } from '@components';

export const useDocumentsTable = ({
  prId: projectId,
  requestId: drawRequestId,
  requestDocuments,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  serviceOrderId,
  source,
  isAllDocumentsTab,
  restoreDocument,
  documents,
}: IDocumentsWrapperProps & {
  documents: IProjectDocument[] | IDocument[];
}): ControllerInterface & { isRightDrawerDocs: boolean } => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const [activeDocId, setActiveDocId] = useUrlParams(
    null,
    'documentId',
    (s) => s.toString(),
    (s) => s.toString(),
  );

  const isMilestoneDocs = useMemo(
    () => Boolean(milestoneId) || Boolean(milestoneSubmitId),
    [milestoneId, milestoneSubmitId],
  );

  const isRightDrawerDocs = useMemo(
    () => isMilestoneDocs || requestDocuments || Boolean(inspectionId) || Boolean(serviceOrderId),
    [isMilestoneDocs, requestDocuments, inspectionId, serviceOrderId],
  );

  const { pdf, gallery, open, close } = useImagePicker();

  const [activeDocument, setActiveDocument] = useState<IProjectDocument | IDocument | null>(null);

  const [rightDrawerParams, setRightDrawerParams] = useState<{
    projectId: string;
    documentId: string;
  }>({
    documentId: '',
    projectId,
  });

  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
    documentId?: string;
  }>({
    projectId,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    ...(inspectionId ? { inspectionId } : {}),
    ...(serviceOrderId ? { serviceOrderId } : {}),
    documentId: rightDrawerParams.documentId,
  });

  const updateRightDrawer = (documentId) => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
    setRightDrawerParams({ projectId, documentId });
  };

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const { handleRightDrawerOpenerClick: handleDocSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  const openComments = useCallback((row) => {
    updateRightDrawer(row?.id);
  }, []);

  const openDocument = (file) => {
    if (!file) return closeDocument();
    setActiveDocId(file.id);
    open([file]);
  };

  const closeDocument = () => {
    setActiveDocId(null);
    close();
  };

  useEffect(() => {
    if (!activeDocId) setActiveDocId(null);
    if (documents?.length && activeDocId) {
      const doc = documents.find((doc) => doc.id === activeDocId);
      if (doc && !pdf && !gallery) open([doc]);
    }
  }, [activeDocId, documents, pdf, gallery, open]);

  const updateProjectDocument = useMutation<
    Response,
    Error,
    ChangeProjectDocumentParam & { scope: string }
  >(patchProjectDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
      if (drawRequestId) {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateDocumentPrivacy = async ({ documentId, scope }) => {
    await updateProjectDocument.mutateAsync({
      projectId,
      documentId,
      scope,
    });
  };

  const openAllDocsSummary = () => {
    handleDocSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__documents_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId });
  };

  const openDocumentSummary = (document: IProjectDocument) => {
    handleDocSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__document_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId, documentId: document.id });
  };

  const canBeEdited = (creatorId) =>
    !isRestricted(PermissionNamesEnums.DOCUMENTS_EDIT, permissions) ||
    isCreatedByUser(creatorId, user.id);

  const columns = useMemo<GridColDef[]>(
    () => [
      nameColumn({ source, open: openDocument }),
      ...(isRightDrawerDocs
        ? []
        : [
            documentTypeColumn({ source }),
            lineItemV2Column({ source }),
            ...(checkIsLender(teamRole) || checkIsInvestor(teamRole)
              ? [
                  companyPrivacyColumn({
                    source,
                    userId: user.id,
                    updateDocumentPrivacy,
                  }),
                ]
              : []),
            ...(isAllDocumentsTab ? [drNumberColumn({ projectId })] : []),
            dateUploadedColumn({ source }),
            uploadedByColumn({ source }),
            processedStatusColumn(),
          ]),
      actionsColumn({
        source,
        isMilestoneDocs,
        openComments,
        restoreDocument,
        setActiveDocument,
        openDocumentSummary,
        canBeEdited,
      }),
    ],
    [
      source,
      teamRole,
      isMilestoneDocs,
      openDocument,
      openComments,
      restoreDocument,
      setActiveDocument,
      openDocumentSummary,
      updateDocumentPrivacy,
      projectId,
      canBeEdited,
    ],
  );

  const closeDocumentEditModal = () => {
    setActiveDocument(null);
  };

  const isDocumentUploadAllowed = useMemo(
    () => !isAllDocumentsTab,
    [isAllDocumentsTab, permissions],
  );

  return {
    columns,
    pdf,
    gallery,
    close: closeDocument,
    rightMenu,
    isRightDrawerDocs,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    openPdfViewer: (file) => openDocument(file),
    activeDocument,
    closeDocumentEditModal,
    isDocumentUploadAllowed,
  };
};
