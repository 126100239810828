import React from 'react';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import snakeCase from 'lodash/snakeCase';

import { CustomCurrencyTextField, CustomTextField, Popup } from '@components';
import * as Controller from './controller';
import { ServiceOrderStatusEnum } from '@interfaces';
import { UpdateIcon } from '@svgAsComponents';
import { ComponentProps } from './interface';

export default function UpdateServiceOrderFieldsPopup({
  initialValue,
  source,
  fieldKey,
  setFieldKey,
  handleUpdate,
  isLoading,
}: Readonly<ComponentProps>) {
  const { options, statusValue, setStatusValue, providerStatusField, providerServiceCostField } =
    Controller.useServiceOrderField(initialValue, fieldKey);

  const renderFieldContent = () => {
    switch (fieldKey) {
      case 'status':
        return {
          title: 'status',
          content: (
            <RadioGroup
              aria-label="inputType"
              name="inputType"
              value={statusValue || ''}
              onChange={(e) => setStatusValue(e.target.value as ServiceOrderStatusEnum)}
              data-cy={`${source}__radio__group`}
            >
              {options.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio data-cy={`${source}__${snakeCase(value)}__option`} />}
                  label={<Typography variant="body2">{label}</Typography>}
                  sx={{ m: 0 }}
                />
              ))}
            </RadioGroup>
          ),
          value: statusValue,
        };
      case 'provider_status':
        return {
          title: 'provider status',
          content: (
            <CustomTextField
              field={providerStatusField}
              label="Provider status"
              autoFocus
              submitCallback={() => handleUpdate(fieldKey, renderFieldContent()?.value)}
            />
          ),
          value: providerStatusField.value,
        };
      case 'provider_service_cost':
        return {
          title: 'provider cost',
          content: (
            <CustomCurrencyTextField
              field={providerServiceCostField}
              label="Provider cost"
              autoFocus
              submitCallback={() => handleUpdate(fieldKey, renderFieldContent()?.value)}
            />
          ),
          value: providerServiceCostField.value,
        };
      default:
        return null;
    }
  };

  return (
    <Popup
      open={!!fieldKey}
      title={`Update ${renderFieldContent()?.title}`}
      maxWidth="xs"
      icon={UpdateIcon}
    >
      <Stack sx={{ width: '100%' }}>
        {renderFieldContent()?.content}
        <Box sx={{ mt: 7 }} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            data-cy={`${source}__cancel__button`}
            variant="text"
            onClick={() => setFieldKey('')}
          >
            Cancel
          </Button>
          <Button
            data-cy={`${source}__confirm__button`}
            disabled={renderFieldContent()?.value === initialValue || isLoading}
            onClick={() => handleUpdate(fieldKey, renderFieldContent()?.value)}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
}
