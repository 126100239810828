import { CrudActionEnum, IRoleNew } from '@interfaces';
import groupBy from 'lodash/groupBy';
import { TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering';
import { useCallback, useMemo, useState } from 'react';

export interface RolesWorkflowInterface {
  roles: IRoleNew[];
  disabled?: boolean;
  isLoading?: boolean;
  crud?: (data: Partial<IRoleNew>, action: CrudActionEnum) => void;
}

export const useRolesWorkflow = ({ roles, crud, disabled }: RolesWorkflowInterface) => {
  const [actionDialog, setActionDialog] = useState<CrudActionEnum | null>(null);
  const [itemForEdit, setItemsForEdit] = useState<Partial<IRoleNew> | null>(null);

  const rolesData = useMemo(() => {
    const groupedRoles = groupBy(roles, 'review_level') as Record<string, IRoleNew[]>;
    const itemsKeys = Object.keys(groupedRoles);
    const items = Object.keys(groupedRoles).map((key) => ({
      id: key,
      label: +key > 0 ? `Review level ${key}` : 'Not include in approval flow',
      children: groupedRoles[key].map((item) => ({
        label: item.company_role,
        ...item,
      })),
    }));
    if (items[0]?.id !== '0') {
      items.unshift({
        id: '0',
        label: 'Not include in approval flow',
        children: [],
      });
    }
    return { items, itemsKeys };
  }, [roles]);

  const crudAction = useCallback(
    (data?: Partial<IRoleNew>, instantAction?: CrudActionEnum) => {
      crud &&
        crud(
          { ...(data || {}), ...(itemForEdit ? { id: itemForEdit?.id } : {}) },
          instantAction || actionDialog,
        );
      closeDialog();
    },
    [crud, itemForEdit, actionDialog],
  );

  const selectItemForDelete = useCallback(
    (data: Partial<IRoleNew>) => {
      if (disabled) return;
      setItemsForEdit(data);
      setActionDialog(CrudActionEnum.DELETE);
    },
    [disabled],
  );

  const selectItemForEdit = useCallback(
    (data: Partial<IRoleNew>) => {
      if (disabled) return;
      setItemsForEdit(data);
      setActionDialog(CrudActionEnum.UPDATE);
    },
    [disabled],
  );

  const closeDialog = useCallback(() => {
    setItemsForEdit(null);
    setActionDialog(null);
  }, []);

  const onItemPositionChange = useCallback(
    ({
      newPosition,
      oldPosition,
      itemId,
    }: {
      itemId: string;
      oldPosition: TreeViewItemReorderPosition;
      newPosition: TreeViewItemReorderPosition;
    }) => {
      const payload: Partial<IRoleNew> = {
        id: itemId,
      };

      if (oldPosition.parentId !== newPosition.parentId) {
        payload.review_level = +newPosition.parentId;
        crudAction(payload, CrudActionEnum.UPDATE);
      }
      return { newPosition };
    },
    [crudAction],
  );

  return {
    rolesData,
    itemForEdit,
    selectItemForEdit,
    actionDialog,
    setActionDialog,
    closeDialog,
    crudAction,
    onItemPositionChange,
    selectItemForDelete,
  };
};
