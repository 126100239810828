import React, {
  Dispatch,
  ReactElement,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  EditPaymentConfigurationTypeInterface,
  EnumTypeForList,
  ErrorDual,
  IDataFieldModel,
  IProject,
  IProjectCreatePayload,
  IProjectFundingSource,
  IPropertyDetail,
  IPropertyDetailsFields,
  IUser,
  MilestoneTag,
  MilestoneTagsTypesEnums,
  QueryNamesEnums,
} from '@interfaces';
import {
  createProjectBuilding,
  createProjectBuildingModelsBulk,
  createProjectBuildingsBulk,
  getProjectFundingSources,
  inviteProjectBorrowers,
  postProject,
  postProjectMilestoneTag,
  updateProjectFields,
} from '@globalService';
import { StringFieldModel } from '@models';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useEditPaymentConfigurationType,
  useLeavePageBlocker,
  useProjectDetailsFields,
  useProjectFieldsV2,
  useSafeSnackbar,
} from '@hooks';
import {
  calculateFraction,
  checkIsFieldsValid,
  getErrorText,
  parsePathErrorDual,
  roundToTwoDigits,
  getPropertyDataForPayload,
} from '@utils';
import { GridRowsProp } from '@mui/x-data-grid';
import { TEAM_ROLES } from '@constants';
import { useLaunchDarklyFlags } from '@context';

export interface ControllerInterface {
  activeStep: number;
  steps: Array<string>;
  nextStep: () => void;
  prevStep: () => void;
  goBack: () => void;
  nextButtonTooltip: string;
  isSubmitting: boolean;
  mainRef: Ref<HTMLDivElement>;
  generalFields: IDataFieldModel;
  loanDetailsFields: IDataFieldModel;
  borrowerDetailsFields: IDataFieldModel;
  getLeavePageConfirmModal: () => ReactElement<string, string>;
  error: string;
  setError: Dispatch<React.SetStateAction<string>>;
  borrowerEquityLocal: StringFieldModel;
  isNewBorrower: boolean;
  setIsNewBorrower: (value: boolean) => void;
  isProductionBuildProject: boolean;
  setIsProductionBuildProject: Dispatch<React.SetStateAction<boolean>>;
  borrowerUser: IUser;
  setBorrowerUser: Dispatch<React.SetStateAction<IUser>>;
  createProjectWithoutBorrowerModal: ConfirmModalHookInterface;
  editPaymentConfigurationData: EditPaymentConfigurationTypeInterface;
  inviteBorrowers: boolean;
  setInviteBorrowers: Dispatch<SetStateAction<boolean>>;
  loan_servicing_statuses: EnumTypeForList[];
  loan_types: EnumTypeForList[];
  propertyDetails: IPropertyDetailsFields;
  propertyRows: GridRowsProp;
  setPropertyRows: Dispatch<SetStateAction<GridRowsProp>>;
  updateProject: () => Promise<void>;
  handleProjectCreationClick: () => void;
  projectId: string;
  unitsNumber: string;
  openSuccessModal: boolean;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
  isCreateByModels: boolean;
}

export const useCreateProject = (): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { getLeavePageConfirmModal, setTriggerExit } = useLeavePageBlocker({
    currentPagePathname: '/projects/add-new',
    confirmTitle: 'Warning',
    isUpdated: true,
    confirmText:
      'Are you sure you want to exit project creation? In this case the data you entered will be lost.',
  });
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isNewBorrower, setIsNewBorrower] = useState(false);
  const [borrowerUser, setBorrowerUser] = useState(null);
  const [isProductionBuildProject, setIsProductionBuildProject] = useState(false);
  const editPaymentConfigurationData = useEditPaymentConfigurationType({});
  const { enqueueSnackbar } = useSafeSnackbar();
  const [inviteBorrowers, setInviteBorrowers] = useState(false);
  const [projectId, setProjectId] = useState<string | null>();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { propertyDetails } = useProjectDetailsFields({});

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const { CREATE_PROJECT_STEPS, loan_servicing_statuses, loan_types } = useProjectFieldsV2({
    isNewBorrower,
    isProductionBuildProject,
  });

  const [unitsRows, setUnitsRows] = useState<GridRowsProp>([]);
  const [modelsRows, setModelsRows] = useState<GridRowsProp>([]);
  const unitsNumber = useMemo(
    () => CREATE_PROJECT_STEPS[0].fields.number_of_units.value,
    [CREATE_PROJECT_STEPS[0].fields.number_of_units.value],
  );
  const nestedLevelName = useMemo(
    () => CREATE_PROJECT_STEPS[0].fields.nested_level_name.value?.name_display,
    [CREATE_PROJECT_STEPS[0].fields.nested_level_name.value],
  );
  const mainLevelName = useMemo(
    () => CREATE_PROJECT_STEPS[0].fields.main_level_name.value?.name_display,
    [CREATE_PROJECT_STEPS[0].fields.main_level_name.value],
  );
  const isCreateByModels = useMemo(() => !!nestedLevelName, [nestedLevelName]);

  useEffect(() => {
    if (!isProductionBuildProject) {
      const nestedNameField = CREATE_PROJECT_STEPS[0].fields.nested_level_name;
      const mainNameField = CREATE_PROJECT_STEPS[0].fields.main_level_name;
      nestedNameField.setValue(null);
      mainNameField.setValue(null);
    }
  }, [isProductionBuildProject]);

  const parentLevelName = useMemo(
    () => CREATE_PROJECT_STEPS[0].fields.main_level_name.value?.name_display,
    [CREATE_PROJECT_STEPS[0].fields.main_level_name.value],
  );

  useEffect(() => {
    if (activeStep === 1 && !isCreateByModels && +unitsNumber > 1) {
      const initialRows = Array.from({ length: +unitsNumber }, (_, index) => ({
        id: index + 1,
        property_name: `${parentLevelName || 'Unit'} ${index + 1}`,
        isEditableV2: true,
        propertyDetails: [],
        rowNumber: index + 1,
      }));
      setUnitsRows(initialRows);
    }
  }, [unitsNumber, activeStep, isCreateByModels, parentLevelName, unitsNumber]);

  useEffect(() => {
    if (activeStep === 1 && isCreateByModels) {
      let idCounter = 1;
      const unitsPerModel = 1;
      const generatedRows = [];

      for (let modelIndex = 0; modelIndex < +unitsNumber; modelIndex++) {
        // Add a model row
        const modelId = idCounter++;
        const modelName = `${parentLevelName} ${modelIndex + 1}`;
        generatedRows.push({
          id: modelId,
          property_name: modelName,
          quantity: unitsPerModel,
          isEditableV2: true,
          parent_level_name: modelName,
          propertyDetails: [],
        });

        // Add associated unit rows for the current model
        for (let unitIndex = 0; unitIndex < unitsPerModel; unitIndex++) {
          const unitId = idCounter++;
          generatedRows.push({
            id: unitId,
            property_name: `${nestedLevelName} ${modelIndex * unitsPerModel + unitIndex + 1}`,
            parentId: modelId,
            isEditableV2: true,
            parent_level_name: modelName,
          });
        }
      }

      setModelsRows(generatedRows);
    }
  }, [unitsNumber, activeStep, isCreateByModels, parentLevelName, nestedLevelName]);

  const {
    state,
    city,
    address_1,
    zip_code,
    project_type,
    property_existing_type,
    property_proposed_type,
    scope_of_work,
    exit_strategy,
  } = CREATE_PROJECT_STEPS[0].fields;
  const {
    external_id,
    funding_date,
    loc_commitment,
    prefunding_cost,
    construction_holdback,
    property_after_repair_value,
    maturity_date,
    extended_maturity_date,
    retainage_rate,
    borrower_equity,
    post_funding_construction_budget,
    estimated_start_date,
    estimated_completion_date,
    payment_configuration_comment,
    loan_status,
    loan_type,
    borrowerEquityLocal,
  } = CREATE_PROJECT_STEPS[2].fields;
  const {
    borrower_email,
    borrower_company_name,
    borrower_company_state,
    borrower_company_address_1,
    borrower_company_city,
    borrower_company_zip_code,
    legal_entity,
    first_name,
    last_name,
    phone,
  } = CREATE_PROJECT_STEPS[3].fields;

  const mainRef = useRef(null);

  useEffect(() => {
    mainRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [activeStep]);

  const [error, setError] = useState<string>('');

  const sendInviteMutation = useMutation<Response, Error, { projectId: string }>(
    inviteProjectBorrowers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
        setTriggerExit(() => ({
          path: `/projects/${projectId}`,
          isNavigationConfirmed: true,
        }));
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const postMsTag = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<MilestoneTag> }
  >(postProjectMilestoneTag, {
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: 'error' });
    },
  });

  const updateLevelNames = async (projectId: string) => {
    const isBothLevels = mainLevelName && nestedLevelName;
    postMsTag.mutateAsync({
      projectId,
      json: {
        name: mainLevelName,
        type: isBothLevels ? MilestoneTagsTypesEnums.MODEL : MilestoneTagsTypesEnums.UNIT,
      },
    });
    if (isBothLevels) {
      postMsTag.mutateAsync({
        projectId,
        json: {
          name: nestedLevelName,
          type: MilestoneTagsTypesEnums.UNIT,
        },
      });
    }
  };

  const projectFundingSourcesQuery = useQuery<{ results: IProjectFundingSource[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_FUNDING_SOURCES, { projectId: projectId }],
    getProjectFundingSources.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const createProjectMutation = useMutation<IProject, ErrorDual, IProjectCreatePayload>(
    postProject,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_STATS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_LIST);
        setProjectId(data.id);
        setActiveStep((prev) => prev + 1);
        setOpenSuccessModal(true);
        if (isProductionBuildProject) {
          updateLevelNames(data.id);
        }
      },
      onError: (error: ErrorDual) => {
        createProjectMutation.reset();
        const errorText = getErrorText(error as ErrorDual);
        setError(errorText);
      },
    },
  );

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_STATS);
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_LIST);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDING_SOURCES, { projectId }]);
      if (inviteBorrowers) {
        sendInviteMutation.mutateAsync({ projectId: projectId });
      } else {
        setTriggerExit(() => ({
          path: `/projects/${projectId}`,
          isNavigationConfirmed: true,
        }));
      }
    },
    onError: (error) => {
      enqueueSnackbar(
        parsePathErrorDual(error) || 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const addBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      details?: IPropertyDetail[];
    }
  >(createProjectBuilding, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
      setTriggerExit(() => ({
        path: `/projects/${projectId}`,
        isNavigationConfirmed: true,
      }));
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addUnitBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json: {
        project: string;
        details?: IPropertyDetail[];
        name?: string;
        sqft?: number;
        description?: string;
      }[];
    }
  >(createProjectBuildingsBulk, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addBuildingModelsMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json?: {
        project: string;
        details?: IPropertyDetail[];
        name?: string;
        sqft?: number;
        description?: string;
      }[];
    }
  >(createProjectBuildingModelsBulk, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const createProjectSubmit = async () => {
    const projectData = {
      address: {
        address_1: address_1.value,
        city: city.value,
        state: state.value?.name,
        zip_code: zip_code.value,
      },
      exit_strategy: exit_strategy.value?.name,
      name: address_1.value + ' ' + city.value,
      project_type: project_type.value?.name,
      property_existing_type: property_existing_type.value?.name,
      property_proposed_type: property_proposed_type.value?.name,
      scope_of_work: scope_of_work.value,
      is_advanced_budget_tracking_enabled: isProductionBuildProject,
      units_number: +unitsNumber,
    };
    await createProjectMutation.mutateAsync({ ...projectData });
  };

  const updateUnits = async () => {
    if (+unitsNumber > 1 && !isCreateByModels) {
      const json = unitsRows.map((row) => ({
        project: projectId,
        details: getPropertyDataForPayload(row.propertyDetails),
        name: row.property_name,
        sqft: row.sqft,
        description: row.description,
      }));

      await addUnitBuildingMutation.mutateAsync({
        projectId,
        json,
      });
    } else if (isCreateByModels) {
      const json = modelsRows
        .filter((row) => !row.parentId)
        .map((row) => ({
          project: projectId,
          details: getPropertyDataForPayload(row.propertyDetails),
          name: row.property_name,
          sqft: row.sqft,
          description: row.description,
          quantity: row.quantity,
        }));

      await addBuildingModelsMutation.mutateAsync({
        projectId,
        json,
      });
    } else {
      await addBuildingMutation.mutateAsync({
        projectId,
        details: getPropertyDataForPayload(propertyDetails?.list || []),
      });
    }
  };

  const updateProject = async () => {
    const sources = projectFundingSourcesQuery.data?.results?.map((source) => {
      const newTotal = source.is_active
        ? source.is_transactable
          ? +construction_holdback.value
          : +borrower_equity.value
        : +prefunding_cost.value;
      return {
        ...source,
        total: newTotal,
      };
    });

    const projectData = {
      address: {
        address_1: address_1.value,
        city: city.value,
        state: state.value?.name,
        zip_code: zip_code.value,
      },
      companies:
        !borrowerUser?.id && !isNewBorrower
          ? []
          : [
              {
                contribution_fraction: calculateFraction(
                  roundToTwoDigits(
                    post_funding_construction_budget.floatValue - construction_holdback.floatValue,
                  ),
                  post_funding_construction_budget.floatValue,
                ),
                role: TEAM_ROLES.Owner,
                ...(borrowerUser?.id
                  ? {
                      ...borrowerUser?.company,
                      members: [{ id: borrowerUser.id, email: borrowerUser.email }],
                    }
                  : {
                      name: borrower_company_name.value,
                      address: {
                        address_1: borrower_company_address_1.value,
                        city: borrower_company_city.value,
                        state: borrower_company_state.value?.name,
                        zip_code: borrower_company_zip_code.value,
                      },
                      members: [
                        {
                          email: borrower_email.value,
                          first_name: first_name.value,
                          last_name: last_name.value,
                          phone: phone.valueToSave,
                        },
                      ],
                    }),
              },
            ],
      ...(estimated_start_date.value
        ? { estimated_start_date: format(estimated_start_date.value, 'yyyy-MM-dd') }
        : {}),
      // estimated_completion_date is written as original also at the moment of project creation, later only estimated can be changed
      ...(estimated_completion_date.value
        ? { original_completion_date: format(estimated_completion_date.value, 'yyyy-MM-dd') }
        : {}),
      ...(estimated_completion_date.value
        ? { estimated_completion_date: format(estimated_completion_date.value, 'yyyy-MM-dd') }
        : {}),
      exit_strategy: exit_strategy.value?.name,
      loan: {
        external_id: external_id.value,
        ...(loan_status.value ? { servicing_status: loan_status.value?.name } : {}),
        ...(loan_type.value ? { type: loan_type.value?.name } : {}),
        ...(funding_date.value ? { funding_date: format(funding_date.value, 'yyyy-MM-dd') } : {}),
        ...(maturity_date.value
          ? { maturity_date: format(maturity_date.value, 'yyyy-MM-dd') }
          : {}),
        ...(extended_maturity_date.value
          ? { extended_maturity_date: format(extended_maturity_date.value, 'yyyy-MM-dd') }
          : {}),
        loc_commitment: +loc_commitment.value,
        construction_holdback: +construction_holdback.value,
        borrower_equity: +borrower_equity.value,
        prefunding_cost: +prefunding_cost.value,
      },
      ...(flags?.['ENG_8718_retrieve_funding_sources'] && sources ? { sources } : {}),
      name: address_1.value + ' ' + city.value,
      project_type: project_type.value?.name,
      scope_of_work: scope_of_work.value,
      property_after_repair_value: +property_after_repair_value.value,
      ...(retainage_rate.value ? { retainage_rate: +retainage_rate.value } : {}),
      is_advanced_budget_tracking_enabled: isProductionBuildProject,
      legal_entity: legal_entity.value,
      payment_configuration_type: editPaymentConfigurationData.configurationType,
      payment_configuration_comment: payment_configuration_comment.value,
    };

    await projectMutation.mutateAsync({
      projectId,
      json: projectData,
    });
  };

  const updateProjectData = async () => {
    await updateUnits();
    await updateProject();
  };

  const createProjectWithoutBorrowerModal = useConfirmationModal();

  const handleNextClick = useCallback(() => {
    if (checkIsFieldsValid(CREATE_PROJECT_STEPS[activeStep].fields)) {
      if (activeStep === 3) {
        updateProjectData();
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }
  }, [CREATE_PROJECT_STEPS, activeStep, projectId]);

  const handleProjectCreationClick = () => {
    if (projectId) {
      setActiveStep((prev) => prev + 1);
    } else {
      createProjectSubmit();
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    activeStep,
    steps: CREATE_PROJECT_STEPS.map((o) => o.title),
    nextStep: handleNextClick,
    prevStep: () => {
      setActiveStep((step) => step - 1);
    },
    goBack,
    nextButtonTooltip: '',
    isSubmitting:
      createProjectMutation.isLoading ||
      sendInviteMutation.isLoading ||
      projectMutation.isLoading ||
      addBuildingMutation.isLoading ||
      addUnitBuildingMutation.isLoading ||
      addBuildingModelsMutation.isLoading,
    mainRef,
    generalFields: CREATE_PROJECT_STEPS[0].fields,
    loanDetailsFields: CREATE_PROJECT_STEPS[2].fields,
    editPaymentConfigurationData,
    borrowerDetailsFields: CREATE_PROJECT_STEPS[3].fields,
    getLeavePageConfirmModal,
    error,
    setError,
    borrowerEquityLocal,
    isNewBorrower,
    setIsNewBorrower,
    isProductionBuildProject,
    setIsProductionBuildProject,
    borrowerUser,
    setBorrowerUser,
    createProjectWithoutBorrowerModal,
    inviteBorrowers,
    setInviteBorrowers,
    loan_servicing_statuses,
    loan_types,
    propertyDetails,
    propertyRows: isCreateByModels ? modelsRows : unitsRows,
    setPropertyRows: isCreateByModels ? setModelsRows : setUnitsRows,
    updateProject,
    handleProjectCreationClick,
    projectId,
    unitsNumber,
    openSuccessModal,
    setOpenSuccessModal,
    isCreateByModels,
  };
};
