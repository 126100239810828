import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getImageUrl } from '@utils';
import { ImageSizeEnum } from '@interfaces';
import { NextButton, PrevButton } from './CarouselButtons';
import { PanoramaViewer, VideoPlayer } from '@components';

const ImageSlickCarousel = ({ slides, startIndex, callbackOnPhotoChange, activePhoto }) => {
  const ref = useRef();
  const xxlMediaQuery = useMediaQuery((theme) => theme.breakpoints.up('xxl'));

  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-dots-custom',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable: false,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    initialSlide: startIndex,
    className: 'slick-center',
    lazyLoad: 'progressive',
    beforeChange: (_oldIndex, newIndex) => {
      if (callbackOnPhotoChange && newIndex !== startIndex) {
        callbackOnPhotoChange(slides[newIndex]?.id);
      }
    },
    fade: true,
  };

  useEffect(() => {
    if (activePhoto.is_panorama) {
      //Timeout is added to ensure that the panorama viewer is loaded after the image is loaded
      setTimeout(() => {
        ref.current?.setPanorama(
          getImageUrl(ImageSizeEnum.LARGE, activePhoto?.file_representations),
        );
      }, 10);
    }
  }, [activePhoto]);

  return (
    <StyledBox sx={{ maxWidth: { xs: '90%', xxl: '1500px' } }}>
      {slides?.length > 1 ? (
        <Slider {...settings}>
          {slides.map((photo) => {
            return (
              <React.Fragment key={photo.id}>
                {photo.is_panorama ? (
                  <Stack>
                    {photo.id === activePhoto.id && (
                      <PanoramaViewer
                        ref={ref}
                        height={xxlMediaQuery ? '600px' : '400px'}
                        width="100%"
                      />
                    )}
                  </Stack>
                ) : (
                  <MediaRenderer photo={photo} />
                )}
              </React.Fragment>
            );
          })}
        </Slider>
      ) : (
        slides.length > 0 && (
          <>
            {slides[0].is_panorama ? (
              <Stack
                sx={{
                  minWidth: { xs: '300px', md: '600px', lg: '750px', xl: '900px', xxl: '1500px' },
                }}
              >
                <PanoramaViewer ref={ref} height={xxlMediaQuery ? '600px' : '400px'} width="100%" />
              </Stack>
            ) : (
              <MediaRenderer photo={slides[0]} />
            )}
          </>
        )
      )}
    </StyledBox>
  );
};

const MediaRenderer = ({ photo }) => {
  if (photo.file_representations.webm) {
    return <VideoPlayer videoUrl={photo.file_representations.webm.url} height="380px" />;
  }

  return (
    <img
      src={
        getImageUrl(ImageSizeEnum.LARGE, photo.file_representations) ||
        getImageUrl(ImageSizeEnum.ORIGINAL, photo.file_representations)
      }
      alt="gallery"
    />
  );
};

export default ImageSlickCarousel;

const StyledBox = styled(Box)({
  '& img': {
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: '100%',
    maxHeight: '450px',
    objectFit: 'contain',
    justifySelf: 'center',
  },
  '& .slick-center': {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: '450px',
  },
  '& .slick-slider .slick-dots ': {
    position: 'relative',
    marginBottom: 20,
  },
  '& .slick-slider .slick-dots li.slick-active button:before': {
    color: '#1f4739',
    opacity: 1,
  },
  '& .slick-slider .slick-dots li button:before': {
    width: 10,
    height: 10,
    fontSize: 8,
  },
  '& .slick-slider .slick-dots li': {
    width: 10,
    height: 10,
  },
});
