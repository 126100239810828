import { ViewerAPI } from 'react-photo-sphere-viewer';
import { Dispatch, SetStateAction } from 'react';
import config from '@config';
import {
  DocumentContentTypeEnum,
  ErrorDual,
  IDocument,
  IFileRepresentation,
  ImageSizeEnum,
  IProject,
  IProofpoint,
  IPropertyDetail,
  ITeam,
  MilestoneTag,
  PropertyDetailsEnum,
  RequestActions,
  ServiceTypeEnum,
  SourcesUsesItem,
  TypePagination,
} from '@interfaces';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { LineItemFilterValues, REFRESH_TOKEN_LIFETIME, TOOLTIP_TEXTS } from '@constants';
import { colors } from '@theme';
import pkg from '../../package.json';
import { GridSortModel } from '@mui/x-data-grid-premium';

export const parsePathErrorDual = ({ errors = {}, ...other }: ErrorDual) => {
  const messages = [];

  function recursiveCollector(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        recursiveCollector(obj[key]);
      } else if (key === 'message' && typeof obj[key] === 'string') {
        messages.push(obj[key]);
      }
    }
  }

  recursiveCollector({ errors, ...other });

  return messages?.join(',') || JSON.stringify({ errors, ...other });
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/),
);

export const isQAEnvironment = window.location.href.includes('sandbox');

export const isProduction = !isLocalhost && !isQAEnvironment;

export const getSortQueryString = ({ sortValue, statusFieldSortName = '' }) => {
  // clone values to change it to parse to query string
  const clonedSortValue = JSON.parse(JSON.stringify(sortValue));
  let result = '';
  // from react-table docs initialState.sortBy: Array<Object<id: columnId, desc: Bool = true>>
  if (clonedSortValue?.length) {
    // server accept only project_name value for sorting so we should transform it
    const replaceFieldNameInSort = (fieldName: string, sortName: string) => {
      const fieldIndex = findIndex(sortValue, { id: fieldName });
      if (fieldIndex !== -1)
        clonedSortValue[fieldIndex] = { id: sortName, desc: sortValue[fieldIndex].desc };
    };
    replaceFieldNameInSort('address.state', 'state');
    replaceFieldNameInSort('coordinator.full_name', 'coordinator');
    replaceFieldNameInSort('project.name', 'project_name');
    replaceFieldNameInSort('inspection', 'inspection_status');
    replaceFieldNameInSort('project.score', 'project_score');
    replaceFieldNameInSort('project.loan.loc_commitment', 'loan_commitment');
    replaceFieldNameInSort('project.original_estimate', 'original_scheduled_value');
    replaceFieldNameInSort('project.address.state', 'state');
    replaceFieldNameInSort('inspection.inspection_agency.display_name', 'inspection_agency');
    replaceFieldNameInSort('inspection.days_waiting_for_inspection', 'days_waiting_for_inspection');
    if (statusFieldSortName) replaceFieldNameInSort('status', statusFieldSortName);
    // server accepts string like sorting=-total,project_name
    clonedSortValue.map((item) => {
      if (result) {
        item.desc ? (result += `,-${item.id}`) : (result += `,${item.id}`);
      } else {
        item.desc ? (result += `-${item.id}`) : (result += `${item.id}`);
      }
    });
  }
  return result;
};

export const debug = async (error, backupText) => {
  try {
    if (error?.message) {
      throw error?.message;
    }
    const err = await error?.response?.json();
    if (config.isDev) console.log('error', err);
    throw err?.message || backupText;
  } catch (err) {
    throw err || backupText || 'Some error';
  }
};

export const getArrayFromObject = (
  object: object,
  keyName1: string,
  keyName2: string,
): {
  [key: string]: string;
}[] =>
  Object?.entries(object)?.map(([key, value]) => ({
    [keyName1]: key,
    [keyName2]: value,
  }));

// this helper used to check if action should be enabled by checking that all arguments are false
export const isActionEnabled = (...args: boolean[]) => args.every((o) => !o);

export const getTooltipText = (args: {
  isCurrentProjectArchived?: boolean;
  isAllNamesNotFill?: boolean;
  tableHasInputError?: boolean;
  isApproveAlreadyDone?: boolean;
  isFundUnavailable?: boolean;
  isRequestOnHold?: boolean;
  isRetainageApprove?: boolean;
  zeroApprovedAmount?: boolean;
  zeroApprovedReallocation?: boolean;
  nonActiveProject?: boolean;
  isCreditIssue?: boolean;
}) => {
  for (const property in args) {
    if (args[property]) {
      return TOOLTIP_TEXTS[property] || '';
    }
  }
  return '';
};

export const getImageUrl = (type: ImageSizeEnum, file_representations: IFileRepresentation) =>
  file_representations?.[type]?.url || null;

export const getValueColor = ({ isInReview = false, isError = false }) => {
  if (isError) return colors.status.error.medium;
  if (isInReview) return colors.status.information.medium;
  return colors.text.dark;
};

export const isCreatedByUser = (createdUserId: string, userId: string) => createdUserId === userId;

export const checkIsResubmit = (action: string) => action === RequestActions.RESUBMIT;
export const checkIsTableEdit = (action: string) => action === RequestActions.TABLE_EDIT;

export const packageJsonVersion = pkg.version;

export const checkIsPHBProject = (project: Partial<IProject>) =>
  project?.is_advanced_budget_tracking_enabled;

export const sortTeamsByApprovalLevel = (teams: ITeam[]) =>
  [...(teams ?? [])].sort((a: ITeam, b: ITeam) => {
    if (a.approval_level === 0 && b.approval_level !== 0) {
      return 1; // a should come after b
    } else if (a.approval_level !== 0 && b.approval_level === 0) {
      return -1; // a should come before b
    } else {
      return a.approval_level - b.approval_level; // Sort by approval level
    }
  });

export const checkEmptyHTMLTags = (value: string) => {
  const regex = /<.*?>/g;
  return !value?.replace(regex, '').trim();
};

export const getMilestoneGroupsTagsIds = (tags = []) => tags.map((item) => item.id)?.join(',');

export const handleContactSupport = () => {
  if (!window.zE) return;
  const conversationBadge = document.querySelector('#conversation-badge');
  const action = conversationBadge.getAttribute('data-btn-action');
  window.zE('messenger', action);
};

export const getTransformedMilestoneTags = (milestoneTags: MilestoneTag[]) =>
  milestoneTags?.reduce((result, item) => {
    result[item.type] = {
      id: item.id,
      name: item.name,
    };
    return result;
  }, {});

export const getPHBFilterOptions = (isRequestTable: boolean) => [
  { ...LineItemFilterValues.ALL },

  { ...LineItemFilterValues.ACTIVE },
  ...(isRequestTable
    ? [
        {
          ...LineItemFilterValues.CURRENT_REQUEST_ONLY,
        },
      ]
    : []),
  {
    ...LineItemFilterValues.NON_ZERO_BUDGET,
  },
];

export const checkMissingValueInOptions = (
  value: string,
  options: { filterValue: string; ids?: string }[],
) => value && options.length && !options.find(({ filterValue }) => filterValue === value);

export const checkIfHorizontalMsExcluded = (groupByOptions, groupByValue) =>
  groupByOptions.find((item) => item.filterValue === groupByValue)?.excludeHorizontalMs;

export const createTuple = (type: DocumentContentTypeEnum, id: string) => `(${type},${id})`;

export const debounceFunction = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getAmountFromSource = (data: SourcesUsesItem[], key: string) =>
  data?.find(({ name }) => name?.key === key)?.amount;

export const findIdInArrByKey = ({ arr, searchedVal, key = 'name_display' }) =>
  arr?.find((type) => type?.[key] === searchedVal)?.id || '';

/* The access token has a 4-hour lifetime,
while the refresh token lasts 6 hours. If active,
the session renews both tokens in the background after the access token expires.
The refresh token’s expiration is tracked in local storage.
If inactive, both tokens expire after 6 hours,
necessitating a user logout before any backend requests are made to avoid errors. */
export const getRefreshTokenExpirationTime = () =>
  JSON.stringify(new Date().getTime() + REFRESH_TOKEN_LIFETIME);

export const openFullScreenPanorama = (
  setShowPanorama: Dispatch<SetStateAction<boolean>>,
  ref: React.MutableRefObject<ViewerAPI>,
  image: IProofpoint | IDocument,
) => {
  setShowPanorama(true);
  //Timeout is added to ensure that the panorama viewer is loaded after the image is loaded
  setTimeout(() => {
    ref.current?.setPanorama(getImageUrl(ImageSizeEnum.LARGE, image?.file_representations));
    ref.current?.enterFullscreen();
  }, 10);
};

export const toggleElementFromArray = <T>(arr: T[], element: T) => {
  if (arr.includes(element)) {
    return arr.filter((e) => e !== element);
  } else {
    return [...arr, element];
  }
};

export const isSQFTDetail = (name) =>
  name?.toLowerCase() === PropertyDetailsEnum.SQFT.toLowerCase();

export const sortPropertiesWithSQFTFirst = (array: IPropertyDetail[]): IPropertyDetail[] => {
  const sqftDetail = array.filter((item) => isSQFTDetail(item.name));
  const otherDetails = array.filter((item) => !isSQFTDetail(item.name));

  return sqftDetail.concat(otherDetails);
};

export const getLinkedObjects = ({
  projectId,
  drawRequestId,
  milestoneId,
  inspectionId,
  serviceOrderId,
}: {
  projectId: string;
  drawRequestId?: string;
  milestoneId?: string;
  inspectionId?: string;
  serviceOrderId?: string;
}) => {
  const projectTuple = createTuple(DocumentContentTypeEnum.PROJECT, projectId);
  const requestTuple = createTuple(DocumentContentTypeEnum.DRAW_REQUEST, drawRequestId);
  const lineItemTuple = createTuple(DocumentContentTypeEnum.MILESTONE, milestoneId);
  const inspectionTuple = createTuple(DocumentContentTypeEnum.INSPECTION, inspectionId);
  const serviceOrderTuple = createTuple(DocumentContentTypeEnum.SERVICE_ORDER, serviceOrderId);

  return [
    ...(drawRequestId ? [requestTuple] : []),
    ...(inspectionId ? [inspectionTuple] : []),
    ...(serviceOrderId ? [serviceOrderTuple] : []),
    ...(!drawRequestId && !inspectionId ? [projectTuple] : []),
    ...(milestoneId ? [lineItemTuple] : []),
  ].join(',');
};

export const getPhotoContentType = ({
  milestoneId,
  drawRequestId,
  inspectionId,
}: {
  milestoneId: string;
  drawRequestId: string;
  inspectionId?: string;
}) => {
  if (inspectionId) return DocumentContentTypeEnum.INSPECTION;
  if (milestoneId) return DocumentContentTypeEnum.MILESTONE;
  if (drawRequestId) return DocumentContentTypeEnum.DRAW_REQUEST;
  return DocumentContentTypeEnum.PROJECT;
};

const nonParseKeys = ['cost_type', 'name', 'description', 'property_name'];

export function diffObjects<T extends Record<string, any>>(
  obj1: T,
  obj2: T,
): Record<string, number> {
  const diff: Record<string, number> = {};

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (obj2[key] !== obj1[key]) {
        diff[key] = nonParseKeys.includes(key) ? obj1[key] : Number(obj1[key]);
      }
    }
  }

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
        diff[key] = nonParseKeys.includes(key) ? obj2[key] : Number(obj2[key]);
      }
    }
  }

  return diff;
}

export const getServiceTypeDisplayName = ({
  serviceTypesMap,
  serviceType,
}: {
  serviceTypesMap: any;
  serviceType: ServiceTypeEnum;
}) => find(serviceTypesMap, { name: serviceType })?.name_display;

export const getPaginationString = (pagination?: TypePagination) =>
  pagination
    ? `&offset=${pagination.page * pagination.rowsPerPage}&limit=${pagination.rowsPerPage}${pagination.id ? `&id=${pagination.id.join(',')}` : ''}`
    : '&limit=9999';

export const getParamString = (params: Record<string, string | boolean | number>) => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      const serializedKey = key === 'restQlParams' ? 'query' : key;
      searchParams.append(serializedKey, String(value));
    }
  });

  return searchParams.toString();
};

export const setDefaultPHBView = ({
  settings,
  isFirstLoad,
  setIsFirstLoad,
  viewTypes,
  activeView,
  setActiveView,
}) => {
  const savedViewType = settings.personal_setting?.PHB_TABLE_VIEW?.view_type;

  if (savedViewType && isFirstLoad) {
    const validViewType = viewTypes.find((item) => item.value === savedViewType)
      ? savedViewType
      : viewTypes[0].value;

    if (validViewType !== activeView) {
      setActiveView(validViewType);
    }

    setIsFirstLoad(false);
  }
};

export const getSortStringByGridSortModel = (
  model: GridSortModel,
  keysMap?: Record<string, string>,
): string => {
  const sortForApi = model.map(
    ({ field, sort }) => `${sort === 'desc' ? '-' : ''}${keysMap?.[field] || field}`,
  );
  return sortForApi.join(',');
};
