import * as React from 'react';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { Box, Button, LinearProgress, Stack } from '@mui/material';
import { CustomTreeItem } from './components/CustomTreeItem';
import { useRolesWorkflow, RolesWorkflowInterface } from './controller';
import { AddRoleDialog } from './components/AddRoleDialog';
import { CrudActionEnum, PopupTypeEnum } from '@interfaces';
import { ConfirmationModal } from '@components';

const RolesWorkflow = ({ roles, disabled, crud, isLoading }: RolesWorkflowInterface) => {
  const {
    rolesData: { items, itemsKeys },
    itemForEdit,
    selectItemForEdit,
    actionDialog,
    setActionDialog,
    onItemPositionChange,
    crudAction,
    selectItemForDelete,
    closeDialog,
  } = useRolesWorkflow({
    roles,
    crud,
    disabled,
  });

  return (
    <Stack>
      <Box sx={{ mb: 2 }}>
        {!disabled && (
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => setActionDialog(CrudActionEnum.CREATE)}
          >
            Add role
          </Button>
        )}
      </Box>

      {isLoading && <LinearProgress sx={{ height: '2px' }} />}
      <RichTreeViewPro
        isItemDisabled={() => disabled}
        items={items}
        itemsReordering
        {...(disabled ? { expandedItems: itemsKeys } : {})}
        defaultExpandedItems={['0', '1', '2', '3']}
        onItemPositionChange={onItemPositionChange}
        canMoveItemToNewPosition={({ itemId, newPosition }) =>
          newPosition.parentId && !itemsKeys.includes(itemId)
        }
        slots={{
          item: CustomTreeItem,
        }}
        slotProps={{
          item: (props) => ({
            ...props,
            selectItemForDelete,
            selectItemForEdit,
          }),
        }}
        experimentalFeatures={{
          indentationAtItemLevel: true,
          itemsReordering: true,
        }}
      />
      <AddRoleDialog
        item={itemForEdit}
        isOpen={actionDialog === CrudActionEnum.UPDATE || actionDialog === CrudActionEnum.CREATE}
        onClose={closeDialog}
        updateHandler={crudAction}
      />
      <ConfirmationModal
        open={actionDialog === CrudActionEnum.DELETE}
        title="Delete role"
        text="Are you sure you want to delete this role."
        onClose={closeDialog}
        confirmCallback={crudAction}
        type={PopupTypeEnum.ERROR}
      />
    </Stack>
  );
};

export default RolesWorkflow;
